import { RefillStatusBadge } from "@/components";
import { Table, Box, Avatar, ActionIcon, Text } from "@axazara/raiton-atoms";
import { SearchStatusOutline } from "@axazara/raiton-icons";
import { Link } from "react-router-dom";
import { Refill, formatDate } from "utilities";

export function RefillsTableRow({
  refill,
  withUser,
}: {
  refill: Refill;
  withUser?: boolean;
}) {
  return (
    <Table.Row key={refill.id}>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">{refill.id}</Text>
      </Table.Cell>

      {withUser && (
        <Table.Cell>
          <Box className="md:flex-row flex flex-col items-center space-x-4">
            <Avatar src={refill.user.profile_photo_url} size={48} />
            <Box>
              <Text className="text-p-01-semibold">
                {refill.user.first_name} {refill.user.last_name}
              </Text>
              <Text className="text-p-01-medium text-neutral-40">
                {refill.user.email}
              </Text>
            </Box>
          </Box>
        </Table.Cell>
      )}

      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">
          {refill.amount_formatted}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01-medium text-success-40">
          {refill.bundle.coins} coins
        </Text>
      </Table.Cell>
      <Table.Cell>
        <RefillStatusBadge status={refill.status} />
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">
          {formatDate(refill.created_at, "en", "D MMM YYYY, HH:mm")}
        </Text>
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <Box className="flex space-x-2">
          <ActionIcon
            component={Link}
            to={`/refills/${refill.id}`}
            color="primary"
            className="p-0.5"
            size="xl"
            radius={100}
          >
            <SearchStatusOutline className="w-6 h-6 text-primary-50" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}
