import { Box, Table } from "@axazara/raiton-atoms";
import { Transactions } from "utilities";
import { TransactionsTableRow } from "./TransactionsTableRow";

export function TransactionsTable({
  transactions,
}: {
  transactions: Transactions;
}) {
  return (
    <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            ID
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Date
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Object
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Amount
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Type
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
            View
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-neutral-80">
          {transactions.map((transaction) => (
            <TransactionsTableRow
              transaction={transaction}
              key={transaction.id}
            />
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
}
