import { Box, Button, OTPInput, Text } from "@axazara/raiton-atoms";
import { Discount } from "utilities";
import { closeModal, useForm } from "@axazara/raiton-molecules";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useDeleteDiscountCode } from "../services";

export function DeleteDiscountCodeModal({ discount }: { discount: Discount }) {
  const deleteDiscountCodeForm = useForm<{ otp_code: string }>({
    initialValues: { otp_code: "" },
  });

  const useDeleteDiscountCodeMutation = useDeleteDiscountCode({
    id: discount.id as string,
  });

  async function handleDeleteDiscountCode(values: Pick<Discount, "otp_code">) {
    try {
      await useDeleteDiscountCodeMutation.mutateAsync(values);
      showSuccessNotification("Discount code is deleted successfully");
      closeModal("");
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }
  return (
    <Box
      component="form"
      onSubmit={deleteDiscountCodeForm.onSubmit(handleDeleteDiscountCode)}
    >
      <Text className="text-center text-neutral-30 text-p-01 mb-4">
        Veuillez entrer le code de confirmation que vous voyez sur l’application
        d’authentification
      </Text>
      <Box className="flex justify-center items-center mb-6">
        <OTPInput
          radius={12}
          size="md"
          required
          onChange={(res) => {
            deleteDiscountCodeForm.setFieldValue("otp_code", res);
          }}
          allowedCharacters="numeric"
        />
      </Box>
      <Box>
        <Button
          color="error.4"
          fullWidth
          className="text-p-01-semibold"
          type="submit"
          disabled={!deleteDiscountCodeForm.isDirty()}
          loading={useDeleteDiscountCodeMutation.isPending}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
}
