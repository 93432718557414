import { ReactQueryService, RestClient } from "@axazara/shuriken";
import { axios } from "./axios";

const getNextPageParam = (lastPage: any) => lastPage.pagination.next_page;
const getPreviousPageParam = (firstPage: any) =>
  firstPage.pagination.previous_page;

export const Api = new RestClient(axios);

export const { useDelete, useGet, useLoadMore, usePatch, usePost, usePut } =
  new ReactQueryService(Api, getNextPageParam, getPreviousPageParam);
