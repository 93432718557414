import React from "react";
import {
  Box,
  Table,
  Text,
  InputField,
  ActionIcon,
  Tag,
  LoadingOverlay,
  Button,
} from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  ClockFill,
  CloseCircleOutline,
  SmsOutline,
  CloseCircleFill,
  AddCircleFill,
} from "@axazara/raiton-icons";
import { useDebouncedValue } from "@axazara/raiton-utils";
import { openConfirmModal, openModal } from "@axazara/raiton-molecules";
import { useQueryClient } from "@tanstack/react-query";
import {
  useDeleteInvitation,
  useGetInvitations,
  useResendInvitation,
} from "../services";
import { AddStaff } from "./AddStaff";
import { AccessControl } from "@/context";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { EmptyState } from "@/components";
import { Invitation } from "@/models";
import { StaffPermissions, formatDate } from "utilities";
import { PaginationControls } from "@/components/PaginationControls";

function InvitationTableRow({
  index,
  page,
  invitation,
}: {
  index: number;
  page: number;
  invitation: Invitation;
}) {
  const queryClient = useQueryClient();
  const deleteMutation = useDeleteInvitation(invitation.id);
  const resendMutation = useResendInvitation(invitation.id);

  async function handleInvitationDelete() {
    try {
      const response = await deleteMutation.mutateAsync("");
      queryClient.invalidateQueries();
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }

  async function handleInvitationResend() {
    try {
      const response = await resendMutation.mutateAsync(undefined);
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }

  return (
    <Table.Row key={invitation.id}>
      <Table.Cell>
        <Text component="span" className="text-p-01-semibold">
          {(index + 1) * page}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Box className="flex items-center space-x-2">
          <Box>
            <Text className="text-p-01-medium">
              {invitation.first_name} {invitation.last_name}
            </Text>
            <Text className="text-neutral-40">{invitation.email}</Text>
          </Box>
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Text component="span" className="text-neutral-40 text-p-01">
          {formatDate(invitation.created_at, "en")}
        </Text>
      </Table.Cell>
      <Table.Cell>
        {invitation.is_accepted ? (
          <Tag
            variant="filled"
            className="normal-case"
            color="success.8"
            size="lg"
            leftSection={<ClockFill className="w-3.5 h-3.5 text-success-50" />}
          >
            <Text className="text-success-50 text-p-01">Accepted</Text>
          </Tag>
        ) : (
          <Tag
            variant="filled"
            className="normal-case"
            color="secondary.8"
            size="lg"
            leftSection={
              <ClockFill className="w-3.5 h-3.5 text-secondary-50" />
            }
          >
            <Text className="text-secondary-50 text-p-01">Pending</Text>
          </Tag>
        )}
      </Table.Cell>
      <Table.Cell>
        <Box className="flex justify-end space-x-2">
          <ActionIcon
            disabled={invitation.is_accepted}
            onClick={() => {
              openConfirmModal({
                title: (
                  <Text className="text-heading-06-semibold text-neutral-30">
                    Confirm resend
                  </Text>
                ),
                labels: { confirm: "Resend", cancel: "Cancel" },
                cancelProps: {
                  size: "xs",
                  variant: "outline",
                  color: "error.4",
                },
                confirmProps: { size: "xs" },
                children: (
                  <Text component="p" className="text-p-02 mb-4">
                    Confirm resend invitation email
                  </Text>
                ),
                centered: true,
                onConfirm: handleInvitationResend,
              });
            }}
            color="primary"
            className="p-0.5"
            size="xl"
            radius={100}
          >
            <SmsOutline className="w-6 h-6" />
          </ActionIcon>
          <ActionIcon
            disabled={invitation.is_accepted}
            onClick={() =>
              openConfirmModal({
                title: (
                  <Text className="text-heading-06-semibold text-neutral-30">
                    Confirm deletion
                  </Text>
                ),
                labels: { confirm: "Delete", cancel: "Cancel" },
                cancelProps: {
                  size: "xs",
                  variant: "outline",
                  color: "error.4",
                },
                confirmProps: { size: "xs" },
                children: (
                  <Text component="p" className="text-p-02 mb-4">
                    Confirm deletion of invitation email
                  </Text>
                ),
                centered: true,
                onConfirm: handleInvitationDelete,
              })
            }
            color="error"
            className="p-0.5"
            size="xl"
            radius={100}
          >
            <CloseCircleOutline className="w-6 h-6" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}

function InvitationsPageRenderer() {
  const searchInputRef = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);
  const { data: invitations, isLoading } = useGetInvitations({
    page,
    search: debouncedSearch,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="flex justify-end mb-8 px-6 space-x-4">
        <AccessControl permissions={StaffPermissions.CREATE_STAFFS}>
          <Button
            onClick={() =>
              openModal({
                centered: true,
                children: <AddStaff />,
                size: "lg",
              })
            }
            size="xs"
            rightIcon={<AddCircleFill className="w-5 h-5" />}
          >
            Add staff member
          </Button>
        </AccessControl>
        <InputField
          placeholder="Search invitation"
          size="xs"
          icon={<SearchNormal1Outline className="w-5 h-5" />}
          className="w-full"
          onChange={(e) => setSearchQuery(e.target.value)}
          rightSection={
            debouncedSearch ? (
              <CloseCircleFill
                className="w-4 h-4 text-gray-600"
                onClick={() => {
                  setSearchQuery("");
                  if (searchInputRef.current)
                    (searchInputRef.current as any).value = "";
                }}
              />
            ) : null
          }
        />
      </Box>
      <Box className="px-6">
        <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
          <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
            <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
              <Table.HeadCell className="w-20 text-neutral-20 text-p-01-semibold">
                ID
              </Table.HeadCell>
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
                Name
              </Table.HeadCell>
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
                Date
              </Table.HeadCell>
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
                Status
              </Table.HeadCell>
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
                Action
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y divide-neutral-80 font-poppins">
              {invitations?.data &&
                invitations?.data.length > 0 &&
                invitations.data.map((invitation, index) => (
                  <InvitationTableRow
                    key={invitation.id}
                    invitation={invitation}
                    index={index}
                    page={page}
                  />
                ))}
            </Table.Body>
          </Table>
        </Box>
        {invitations?.data && (
          <PaginationControls
            page={page}
            pagination={invitations?.pagination}
            onPageChange={setPage}
          />
        )}
      </Box>
    </Box>
  );
}

export function InvitationsTab() {
  const { data: invitations, isLoading } = useGetInvitations({ page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (invitations && invitations?.data?.length > 0)
    return <InvitationsPageRenderer />;

  return (
    <EmptyState
      title="No invitations yet"
      primaryAction={
        <AccessControl permissions={StaffPermissions.CREATE_STAFFS}>
          <Button
            onClick={() =>
              openModal({
                centered: true,
                children: <AddStaff />,
                size: "lg",
              })
            }
            size="xs"
          >
            Add staff member
          </Button>
        </AccessControl>
      }
    />
  );
}
