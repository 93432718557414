import {
  Table,
  Avatar,
  Box,
  ActionIcon,
  Text,
  Tooltip,
  Badge,
} from "@axazara/raiton-atoms";
import { SearchStatusOutline } from "@axazara/raiton-icons";
import { Link } from "react-router-dom";
import { Order, formatDate } from "utilities";
import { OrderStatusBadge } from "./OrderStatusBadge";

export function OrdersTableRow({ order }: { order: Order }) {
  return (
    <Table.Row key={order.id}>
      <Table.Cell className="text-p-01 text-neutral-40">{order.id}</Table.Cell>
      <Table.Cell>
        <Tooltip label={<Text>{order?.country?.name}</Text>}>
          <Avatar src={order.country.flag} size={32} />
        </Tooltip>
      </Table.Cell>
      <Table.Cell>
        <Tooltip label={<Text>{order?.provider?.name}</Text>}>
          <Badge
            inline
            offset={10}
            size={14}
            position="bottom-end"
            classNames={{ badge: "bg-transparent" }}
            label={
              <Box className="bg-white rounded-full p-[1px]">
                <Avatar src={order?.provider?.icon_url} size={16} />
              </Box>
            }
          >
            <Avatar src={order?.service?.icon_url} size={48} />
          </Badge>
        </Tooltip>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">
          {order.phone_number?.international}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">{order.price} coins</Text>
      </Table.Cell>
      <Table.Cell>
        <OrderStatusBadge status={order.status} />
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">
          {formatDate(order.created_at, "en", "D MMM YYYY, HH:mm:ss")}
        </Text>
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <Box className="flex space-x-2">
          <ActionIcon
            component={Link}
            to={`/orders/${order.id}`}
            color="primary"
            className="p-0.5"
            size="xl"
            radius={100}
          >
            <SearchStatusOutline className="w-6 h-6 text-primary-50" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}
