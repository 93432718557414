import { Box, Tabs } from "@axazara/raiton-atoms";
import { PeopleFill, SmsOutline, DocumentOutline } from "@axazara/raiton-icons";
import { AccessControl } from "@/context";
import { ActivitiesTab, InvitationsTab, StaffsTab } from "../../components";
import { useSetPageMeta } from "@/hooks";
import { StaffPermissions } from "utilities";

export function Staffs() {
  useSetPageMeta({
    title: "Staffs",
    icon: PeopleFill,
  });

  return (
    <Box
      component={AccessControl}
      permissions={[
        StaffPermissions.CREATE_STAFFS,
        StaffPermissions.UPDATE_STAFFS,
        StaffPermissions.READ_STAFFS,
      ]}
      isPage
    >
      <Box className="pt-4">
        <Tabs
          styles={(theme) => ({
            tabsList: {
              borderBottomWidth: 1,
              borderColor: theme.colors.neutral[8],
              flexWrap: "nowrap",
            },
            tab: {
              color: theme.colors.neutral[6],
            },
          })}
          defaultValue="staffs_member"
        >
          <Tabs.List className="max-w-md mx-auto w-full gap-5 text-p-01-semibold mb-12">
            <Tabs.Tab
              icon={<PeopleFill className="w-5 h-5 text-primary-4" />}
              value="staffs_member"
            >
              Staffs member
            </Tabs.Tab>
            <Tabs.Tab
              icon={<SmsOutline className="w-5 h-5 text-primary-4" />}
              value="invitations"
            >
              Invitations
            </Tabs.Tab>
            <Tabs.Tab
              icon={<DocumentOutline className="w-5 h-5 text-primary-4" />}
              value="activity"
            >
              Activities
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="staffs_member">
            <StaffsTab />
          </Tabs.Panel>
          <Tabs.Panel value="invitations">
            <InvitationsTab />
          </Tabs.Panel>
          <Tabs.Panel value="activity">
            <ActivitiesTab />
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
}
