import { Avatar, Box, Button, Surface, Text } from "@axazara/raiton-atoms";
import { AddCircleFill } from "@axazara/raiton-icons";
import { Link } from "react-router-dom";
import { Providers } from "utilities";

export function ProviderAccountCard({ providers }: { providers: Providers }) {
  return (
    <Box className="grid grid-cols-4 gap-9">
      {providers &&
        providers.map((provider) => {
          return (
            <Surface
              key={provider.id}
              className="border border-neutral-80 p-6 space-y-7"
            >
              <Box className="flex items-center gap-4">
                <Avatar src={provider.icon_url} size={64} />
                <Text className="text-heading-06-sm-semibold">
                  {provider.name}
                </Text>
              </Box>
              <Button
                component={Link}
                to={`/provider-account/add-provider-account/${provider.short_code}`}
                fullWidth
                className="bg-primary-90 text-primary-50"
                rightIcon={<AddCircleFill className="w-6 h-6" />}
              >
                Add provider
              </Button>
            </Surface>
          );
        })}
    </Box>
  );
}
