import React from "react";
import { Box, InputField, DatePickerInput } from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  Calendar2Outline,
  ArrowDownOutline,
} from "@axazara/raiton-icons";
import { useTranslation } from "react-i18next";
import { StatusFilterMenu, StatusType } from "./StatusFilterMenu";
import {
  Countries,
  Country,
  PaginatedProviderAccounts,
  ProviderAccount,
  Service,
} from "utilities";
import { CountryFilterMenu } from "./CountryFilterMenu";
import { ServiceFilterMenu } from "./ServiceFilterMenu";
import { ProviderFilterMenu } from "./ProviderFilterMenu";

type OrdersFilterProps = {
  // Search related fields
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  debouncedSearch: string;
  searchCountryQuery: string;
  setSearchCountryQuery: React.Dispatch<React.SetStateAction<string>>;
  searchProviderQuery: string;
  setSearchProviderQuery: React.Dispatch<React.SetStateAction<string>>;
  searchServiceQuery: string;
  setSearchServiceQuery: React.Dispatch<React.SetStateAction<string>>;

  // Status filter fields
  selectedStatus: StatusType | null;
  setSelectedStatus: React.Dispatch<React.SetStateAction<StatusType | null>>;
  statusData: StatusType[];
  openedStatusFilter: boolean;
  setOpenedStatusFilter: React.Dispatch<React.SetStateAction<boolean>>;

  // Date range filter fields
  selectedDateRange: [Date | null, Date | null];
  setSelectedDateRange: React.Dispatch<
    React.SetStateAction<[Date | null, Date | null]>
  >;

  // Country filter fields
  countries: Countries | undefined;
  selectedCountry: Country | null;
  setSelectedCountry: React.Dispatch<React.SetStateAction<Country | null>>;
  openedCountryFilter: boolean;
  setOpenedCountryFilter: React.Dispatch<React.SetStateAction<boolean>>;

  // Service filter fields
  services: Service[] | undefined;
  selectedService: Service | null;
  setSelectedService: React.Dispatch<React.SetStateAction<Service | null>>;
  openedServiceFilter: boolean;
  setOpenedServiceFilter: React.Dispatch<React.SetStateAction<boolean>>;

  // Provider filter fields
  providers: PaginatedProviderAccounts | undefined;
  selectedProvider: ProviderAccount | null;
  setSelectedProvider: React.Dispatch<
    React.SetStateAction<ProviderAccount | null>
  >;
  openedProviderFilter: boolean;
  setOpenedProviderFilter: React.Dispatch<React.SetStateAction<boolean>>;

  // URL parameter handling
  setUrlParams: any;
};

export const OrdersFilter = ({
  searchQuery,
  setSearchQuery,
  selectedStatus,
  setSelectedStatus,
  selectedDateRange,
  setSelectedDateRange,
  statusData,
  countries,
  setSelectedCountry,
  services,
  setSelectedService,
  openedStatusFilter,
  setOpenedStatusFilter,
  openedCountryFilter,
  setOpenedCountryFilter,
  selectedCountry,
  openedServiceFilter,
  setOpenedServiceFilter,
  selectedService,
  setUrlParams,
  debouncedSearch,
  searchCountryQuery,
  setSearchCountryQuery,
  searchServiceQuery,
  setSearchServiceQuery,

  providers,
  selectedProvider,
  setSelectedProvider,
  openedProviderFilter,
  setOpenedProviderFilter,
  searchProviderQuery,
  setSearchProviderQuery,
}: OrdersFilterProps) => {
  const { t } = useTranslation();

  return (
    <Box className="flex gap-2 w-full">
      {/* Search Input */}
      <InputField
        radius={12}
        placeholder="Search order"
        size="xs"
        icon={<SearchNormal1Outline className="w-5 h-5" />}
        onChange={({ target }) => {
          setSearchQuery(target.value);
          setUrlParams({ search: target.value });
        }}
        className="w-full"
        value={searchQuery ?? ""}
        rightSection={
          <Box>
            {debouncedSearch ? (
              <CloseCircleFill
                className="w-4 h-4 text-neutral-40"
                onClick={() => {
                  setSearchQuery("");
                  setUrlParams({ search: "" });
                }}
              />
            ) : null}
          </Box>
        }
      />

      <Box className="flex flex-row gap-2">
        <StatusFilterMenu
          statusData={statusData}
          selectedStatus={selectedStatus}
          openedStatusFilter={openedStatusFilter}
          setSelectedStatus={setSelectedStatus}
          setOpenedStatusFilter={setOpenedStatusFilter}
        />

        <CountryFilterMenu
          countries={countries}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          searchCountryQuery={searchCountryQuery}
          setSearchCountryQuery={setSearchCountryQuery}
          openedCountryFilter={openedCountryFilter}
          setOpenedCountryFilter={setOpenedCountryFilter}
        />
        <ServiceFilterMenu
          services={services}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          searchServiceQuery={searchServiceQuery}
          setSearchServiceQuery={setSearchServiceQuery}
          openedServiceFilter={openedServiceFilter}
          setOpenedServiceFilter={setOpenedServiceFilter}
        />
        <ProviderFilterMenu
          providers={providers}
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
          searchProviderQuery={searchProviderQuery}
          setSearchProviderQuery={setSearchProviderQuery}
          openedProviderFilter={openedProviderFilter}
          setOpenedProviderFilter={setOpenedProviderFilter}
        />

        {/* Date Range Picker */}
        <DatePickerInput
          size="xs"
          type="range"
          clearable
          icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
          // @ts-ignore
          placeholder={`${t("filter_by_date")}`}
          value={selectedDateRange}
          numberOfColumns={2}
          styles={(theme) => ({
            root: {
              "> div": {
                borderColor: theme.colors.neutral[8],
              },
            },
          })}
          onChange={setSelectedDateRange}
          allowSingleDateSelectionInRange
          rightSection={
            selectedDateRange[1] ? null : (
              <ArrowDownOutline className="w-4 h-4 text-neutral-40" />
            )
          }
        />
      </Box>
    </Box>
  );
};

export * from "./StatusFilterMenu";
