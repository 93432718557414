import {
  formatDate,
  PaginatedProviderAccounts,
  Provider,
  ProviderAccount,
  Providers,
} from "utilities";
import { DateRange } from "@/types";
import { buildFetchParams } from "@axazara/raiton-utils";
import { useGet, usePost, usePut } from "@/lib";
import { ApiEndpoints } from "@/configs";

export const useGetInstanceProviders = ({
  page = 1,
  search,
  initialData,
  dateRange = [null, null],
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedProviderAccounts;
  dateRange?: DateRange;
}) => {
  const requestPage = search ? 1 : page;
  const [fromDate, toDate] = dateRange;
  let params = buildFetchParams({
    page: requestPage,
    search,
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
  });
  return useGet<PaginatedProviderAccounts>(
    ApiEndpoints.providersInstance,
    params,
    { initialData },
  );
};

export const useUpdateProviderInformation = (id: string) =>
  usePut<Partial<ProviderAccount>, ProviderAccount>(
    `${ApiEndpoints.providersInstance}/${id}`,
  );

export const useGetProviders = ({ search }: { search?: string }) => {
  let params = buildFetchParams({
    search,
  });
  return useGet<Providers>(ApiEndpoints.providers, params);
};
export const useGetProvider = (code: string) =>
  useGet<Provider>(`${ApiEndpoints.providers}/${code}`);

export const useAddProviderAccount = () =>
  usePost<Partial<Provider>, Provider>(ApiEndpoints.providersInstance);
