import { Box, OTPInput, Button, Text } from "@axazara/raiton-atoms";
import { useForm, closeModal } from "@axazara/raiton-molecules";
import { InformationFill } from "@axazara/raiton-icons";
import { showSuccessNotification, showErrorNotification } from "@/utils";
import { useResetStaffOTP } from "../services";
import { Staff } from "@/models";

export function ResetStaffOTPConfirmationModal({ staff }: { staff: Staff }) {
  const resetStaffOTPMutation = useResetStaffOTP(staff.id);

  const resetStaffOTPForm = useForm<{ otp_code: string }>({
    initialValues: { otp_code: "" },
  });

  async function handleSuspendUserForm(values: { otp_code: string }) {
    try {
      const response = await resetStaffOTPMutation.mutateAsync(values);
      closeModal("");
      showSuccessNotification((response as any)?.message);
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  return (
    <Box className="px-8 pb-8">
      <Box className="flex items-center space-x-3 mb-6">
        <InformationFill className="w-5 text-critical-40" />
        <Text className="text-heading-06-semibold text-neutral-30">
          Are you sure you want to reset OTP for {staff.full_name}
        </Text>
      </Box>
      <Box
        component="form"
        onSubmit={resetStaffOTPForm.onSubmit((values) =>
          handleSuspendUserForm(values),
        )}
      >
        <Box className="flex flex-col justify-center items-center">
          <OTPInput
            radius={12}
            size="lg"
            {...resetStaffOTPForm.getInputProps("otp_code")}
            required
            inputClassName="mb-4 text-p-02 text-neutral-50"
            onChange={(res) => {
              resetStaffOTPForm.setFieldValue("otp_code", res);
            }}
            allowedCharacters="numeric"
          />
          <Text className="text-p-01 text-neutral-60 mb-7">
            Enter the 6 digit 6 code from Google Authenticator.
          </Text>
        </Box>
        <Box className="md:flex justify-center md:space-x-4 mt-4">
          <Button
            variant="outline"
            className="w-full md:w-auto"
            onClick={() => closeModal("")}
          >
            Cancel
          </Button>
          <Button
            className="mt-4 md:mt-0"
            fullWidth
            color="error.3"
            disabled={!resetStaffOTPForm.isDirty()}
            loading={resetStaffOTPMutation.isPending}
            type="submit"
          >
            Reset
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
