import {
  Box,
  Divider,
  Select,
  Textarea,
  Text,
  OTPInput,
  Button,
  LoadingOverlay,
} from "@axazara/raiton-atoms";
import { closeModal, useForm } from "@axazara/raiton-molecules";
import { Order, RefundOrderPayload } from "utilities";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useGetRefundOrderReasons, useRefundOrder } from "../services";
import { useQueryClient } from "@tanstack/react-query";
import { ApiEndpoints } from "@/configs";

export function OrderRefundModal({
  order,
  refetch,
}: {
  order: Order;
  refetch: any;
}) {
  const queryClient = useQueryClient();

  const { data: reasons, isLoading } = useGetRefundOrderReasons();

  const refundOrderForm = useForm<RefundOrderPayload>({
    initialValues: {
      refund_note: "",
      refund_reason: "",
      otp_code: "",
    },
  });

  const refundOrderMutation = useRefundOrder({ id: order.id });

  if (isLoading) return <LoadingOverlay visible />;

  async function handleRefundOrder(params: any) {
    try {
      const response = await refundOrderMutation.mutateAsync(params);
      queryClient.invalidateQueries({ queryKey: [ApiEndpoints.orders] });

      showSuccessNotification((response as any)?.message);
      closeModal("");
      refetch();
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }
  return (
    <Box
      component="form"
      className="space-y-4"
      onSubmit={refundOrderForm.onSubmit(handleRefundOrder)}
    >
      {reasons && (
        <Select
          {...refundOrderForm.getInputProps("refund_reason")}
          placeholder="Reason"
          data={reasons as any}
          optionResolver={(reason: any) => ({
            label: reason.label,
            value: reason.value,
          })}
          classNames={{ label: "text-neutral-50 mb-2 text-p-02" }}
          type="text"
          size="sm"
          className="mb-5"
          required
          radius={12}
          itemComponent={({ label, value, ...others }) => (
            <Box className="py-2" key={value} {...others}>
              <Box className="flex flex-row items-center space-x-2">
                <Text className="text-p-01-medium">{label}</Text>
              </Box>
            </Box>
          )}
          styles={(theme) => ({
            input: {
              borderColor: theme.colors.neutral[6],
            },
          })}
        />
      )}
      <Textarea
        {...refundOrderForm.getInputProps("refund_note")}
        className="font-poppins text-p-02"
        minRows={5}
        radius={8}
        autosize
        required
        placeholder="Internal note"
        rows={5}
        styles={(theme) => ({
          label: {
            color: theme.colors.neutral[0],
            fontWeight: 600,
            fontSize: 16,
            marginBottom: 8,
          },
          input: {
            borderColor: theme.colors.neutral[7],
            backgroundColor: theme.colors.neutral[9],
            resize: "vertical",
          },
        })}
      />
      <Divider color="neutral.9" className="border-neutral-90" />
      <Box className="flex flex-col items-center justify-center">
        <Text className="text-p-01 text-neutral-30 text-center mb-4">
          Please enter the confirmation code you see on the authentication
          application
        </Text>
        <OTPInput
          {...refundOrderForm.getInputProps("otp_code")}
          radius={12}
          size="lg"
          allowedCharacters="numeric"
          className="flex justify-center"
          length={6}
          onChange={(res) => {
            refundOrderForm.setFieldValue("otp_code", res);
          }}
        />
      </Box>
      <Box className="flex justify-end mb-4">
        <Button
          disabled={!refundOrderForm.isDirty()}
          loading={refundOrderMutation.isPending}
          className="text-p-01-semibold"
          type="submit"
          fullWidth
        >
          Update
        </Button>
      </Box>
    </Box>
  );
}
