import { appPaths } from "@/configs";
import {
  Box,
  Text,
  ActionIcon,
  Avatar,
  Tabs,
  LoadingOverlay,
} from "@axazara/raiton-atoms";
import {
  Profile2userFill,
  ArrowCircleLeftOutline,
  TaskSquareOutline,
  BoxFill,
  ArrangeSquareOutline,
  RefreshOutline,
} from "@axazara/raiton-icons";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useGetUser } from "../../services";
import { useSetPageMeta } from "@/hooks";
import {
  UserInformationTab,
  OrdersTab,
  TransactionsTab,
  RefillsTab,
} from "../../components";

function UsersDetailsPageRender({ id }: { id: string }) {
  const navigate = useNavigate();
  const { data: user, isLoading } = useGetUser(id);
  if (isLoading) return <LoadingOverlay visible />;

  return (
    <Box className="pt-4">
      <Box className="flex items-center gap-6">
        <ActionIcon radius={100} onClick={() => navigate(appPaths.root)}>
          <ArrowCircleLeftOutline className="w-6 h-6" />
        </ActionIcon>
        <Box className="md:flex-row flex flex-col items-center space-x-4">
          <Avatar src={user?.profile_photo_url} size={48} />
          <Box>
            <Text className="text-p-01-semibold text-neutral-20 mb-1.5">
              {user?.first_name} {user?.last_name}
            </Text>
            <Text className="text-caption-medium text-neutral-60">
              @{user?.id}
            </Text>
          </Box>
        </Box>
      </Box>

      <Tabs
        styles={(theme) => ({
          tabsList: {
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            borderBottomWidth: 1,
            borderColor: theme.colors.neutral[8],
            flexWrap: "nowrap",
            "@media (max-width: 670px)": {
              overflow: "auto hidden",
            },
          },
          tab: {
            color: theme.colors.neutral[6],
          },
        })}
        defaultValue="information"
      >
        <Box className="justify-center">
          <Tabs.List className="mb-12 max-w-4xl mx-auto">
            <Tabs.Tab
              className="md:text-p-02-semibold text-p-01-semibold w-full"
              value="information"
              icon={<TaskSquareOutline className="w-6 h-6" />}
            >
              Information
            </Tabs.Tab>
            <Tabs.Tab
              className="md:text-p-02-semibold text-p-01-semibold w-full"
              value="orders"
              icon={<BoxFill className="w-6 h-6" />}
            >
              Orders
            </Tabs.Tab>

            <Tabs.Tab
              className="md:text-p-02-semibold text-p-01-semibold w-full"
              value="transactions"
              icon={<ArrangeSquareOutline className="w-6 h-6" />}
            >
              Transactions
            </Tabs.Tab>
            <Tabs.Tab
              className="md:text-p-02-semibold text-p-01-semibold w-full"
              value="history"
              icon={<RefreshOutline className="w-6 h-6" />}
            >
              Refills
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="information">
            {user && <UserInformationTab user={user} />}
          </Tabs.Panel>
          <Tabs.Panel value="orders">
            {user && <OrdersTab user={user} />}
          </Tabs.Panel>
          <Tabs.Panel value="transactions">
            {user && <TransactionsTab user={user} />}
          </Tabs.Panel>
          <Tabs.Panel value="history">
            {user && <RefillsTab user={user} />}
          </Tabs.Panel>
        </Box>
      </Tabs>
    </Box>
  );
}

export function UserDetails() {
  useSetPageMeta({
    title: "Users",
    icon: Profile2userFill,
  });

  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.users.path} />;
  }

  return <UsersDetailsPageRender id={id} />;
}
