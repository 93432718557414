export default {
  // 1. General Component Labels
  "component.name": "Name",
  "component.status": "Status",
  "component.description": "Description",
  "component.currency": "Currency",
  "component.date": "Date",
  "component.language": "Language",
  "component.amount": "Amount",
  "component.No": "N°",
  "component.unknownError": "Something went wrong, please try again",
  "component.All": "All",
  "component.Active": "Active",
  "component.Inactive": "Inactive",
  "component.onceTransactionIsConfirmed": "Customer has not made payment yet.",
  "component.filterByDate": "Filter by date",
  "component.informations": "Informations",
  "component.payments": "Payments",
  "component.payouts": "Payouts",
  "component.customer": "Customer",
  "component.customers": "Customers",
  "component.reference": "Reference",
  "component.customerSearchPlaceholder": "Search customer",
  "component.developers": "Developers",
  "component.copy": "Copy",
  "component.action": "Action",
  "component.copied": "Copied",
  "component.search": "Search",
  "component.edit": "Edit",
  "component.object": "Object",
  "component.gateway": "Gateway",
  "component.settings": "Settings",
  "component.showingResults": "Showing {{from}} to {{to}} of {{total}} results",
  showingResults: "Showing {{from}} to {{to}} of {{total}} results",
  "component.URL": "URL",
  "component.method": "Method",
  "component.revoke": "Revoke",
  "component.createdAt": "Created at",
  "component.signAt": "Sign up at",
  "component.revokedAt": "Revoked at",
  "component.expireAt": "Expire at",
  "component.lastLogin": "Last login",
  "component.appName": "App name",
  "component.website": "Website",
  "component.cancel": "Cancel",
  "component.never": "Never",
  "component.admin": "Admin",
  "component.developer": "Developer",
  "component.member": "Member",
  "component.webhooks": "Webhooks",
  "component.verified": "Verified",
  "component.unverified": "Verified",
  "component.enable": "Enable",
  "component.disable": "Disable",
  "component.2faStatus": "Two factor status",

  // 2. Transaction Management
  "component.allTransactions": "See all transactions",
  "component.transactionPending": "Pending",
  "component.transactionFailed": "Failed",
  "component.transactionSuccess": "Success",
  "component.transactionInitiated": "Initiated",
  "component.transactionCancelled": "Cancelled",
  "component.transactionSearchPlaceholder": "Search transaction",

  // 3. Payment Details
  "component.paymentDetails.id": "Transaction ID",
  "component.paymentContext": "Context",
  "component.ip": "IP address",
  "component.device": "Device",
  "component.metadata": "Metadata",
  "component.paymentContext.browser": "Browser",
  "component.paymentContext.language": "Language",

  // 4. Payment and Payout Methods
  "component.methodAlreadyAdded": "This method is already linked to your apps.",
  "component.paymentMethod.search": "Search payment method",
  "component.paymentMethod.addButton": "Add {{method}}",
  "component.paymentMethod.compatibleGatewaySuggestion.empty":
    "Your app does not has any payment gateway supported available. Please add a payment gateway first.",
  "component.paymentMethod.linkToYourApp": "Link {{method}} to your app",
  "component.paymentMethod.updateLinkedPaymentGateway":
    "Update linked payment gateway",
  "component.paymentMethod.linkPaymentGateway": "Link payment gateway",
  "component.paymentMethod.add": "Add payment method",
  "component.payoutMethod.compatibleGatewaySuggestion.empty":
    "Your app does not has any payout gateway supported available. Please add a payout gateway first.",
  "component.payoutMethod.linkToYourApp": "Link {{method}} to your app",
  "component.payoutMethod.linkPayoutGateway": "Link payout gateway",
  "component.payoutMethod.add": "Add payout method",

  // 5. Payment and Payout Gateway Management
  "component.paymentGateway.id": "Gateway transaction Id",
  "component.paymentGateway.status": "Gateway transaction statut",
  "component.paymentGateway.availableList": "Available payment gateways",
  "component.paymentGateway.searchPlaceholder": "Search payment gateway",
  "component.paymentGateway.selectPlaceholder": "Select a payment gateway",
  "component.paymentGateway": "Payment gateway",
  "component.paymentGateway.add": "Add payment gateway",
  connect: "Connect {{name}}",
  "component.payoutGateway.availableList": "Available payout gateways",
  "component.payoutGateway.searchPlaceholder": "Search payout gateway",
  "component.payoutGateway.selectPlaceholder": "Select a payout gateway",
  "component.payoutGateway": "Payout gateway",
  filter_by_method: "Filter by method",
  filter_by_gateway: "Filter by gateway",
  filter_by_currency: "Filter by currency",
  filter_by_date: "Filter by date",
  search_transaction: "Search transaction",

  // 6. Customer Details
  "component.viewCustomer": "View customer",
  "component.firstName": "First name",
  "component.lastName": "Last name",
  "component.email": "Email",
  "component.phone": "Phone",
  "component.address": "Address",
  "component.city": "City",
  "component.zipCode": "Zip code",
  "component.country": "Country",
  "component.state": "State",

  // 7. API Details
  "component.apiKeys": "API Keys",
  "component.apiKey.deleted": "API key deleted successfully!",
  "component.webhook": "Webhook",
  "component.webhookUrl": "Webhook URL",
  "component.webhookSecret": "Webhook Secret",
  "component.addedBy": "Added by",

  // 8. Team Management
  "component.team.members": "Team members",
  "component.team.member": "Team member",
  "component.team.invites": "Invites",
  "component.team.inviteAt": "Invite at",
  "component.team.invitedBy": "Invited by",
  "component.team.Add": "Add team member",
  "component.team.invite": "Invite",
  "component.role": "Role",

  // 9. Settings
  "component.settingsTab.transactionEmail": "Transaction emails",
  "component.settingsTab.customerEmail": "Customer receipt",
  "component.settingsTab.payoutEmail": "Payout emails",
  "component.settingsTab.supportEmail": "Support email",
  "component.settingsTab.supportPhone": "Support phone",
  "component.settingsTab.transactionEmail.description":
    "Send me an email when a transaction is completed.",
  "component.settingsTab.customerEmail.description":
    "Send an email to the customer when a transaction is completed.",
  "component.settingsTab.payoutEmail.description":
    "Send me an email when a payout is completed.",

  "component.compatibleGatewaySuggestion":
    "Some gateways that support this method",
  "component.addedAt": "Added at",
  "component.view": "View",

  "component.supportedCurrencies": "Supported currencies",
  "component.supportedMethods": "Supported methods",
  "component.supportedCountries": "Supported countries",
  "component.visitWebsite": "Visit website",
  "component.learnMore": "Learn more",
  "component.supportedGateways": "Supported gateways",
  "component.sandboxMode": "Sandbox mode",
  attempt_info: "Your customer attempts to authorize payment.",

  "component.webhookDescription":
    "Please add this webhook url to your account. This will allow Moneroo to receive notification from the gateway each time a transaction is completed or an event occurs.",
  "component.updateInformation": "Update information",
  "component.updateSettings": "Update settings",
  "component.updateGatewayInformation": "Update gateway information",

  "component.profile.edit.description":
    "To edit your profile information, you will be redirect to your Axa Zara Account.",

  //Gateways
  "component.accountName": "Account name",
  "component.developer.createApiKey": "Create API key",
  "component.developer.keyType": "Type",
  "component.developer.ApiKeyDetails": "API key details",
  "component.developer.lastUsed": "Last used",

  //Utilities
  "component.team.auditLogs": "Audit logs",
  "component.team.inviteDetails": "Invite details",
  "component.admin.description": "Manage all aspects of the apps.",
  "component.developer.description":
    "Manage developers settings, gateways and methods.",
  "component.member.description":
    "Can only view translations, gateways and methods.",

  "component.apiKey.description.placeholder":
    "Describe this key and the location where it's stored.",
  "component.apiKey.type.placeholder": "Select a key type",
  "component.apiKey.expiresAt.placeholder": "Expiration date",
  "component.apiKey.keepItSafe": "Keep your key safe",
  "component.apiKey.copy.title": "Your new API key",
  "component.apiKey.private": "Private",
  "component.apiKey.public": "Public",
  "component.apiKey.keepItSafe.description":
    "Please save this secret key somewhere safe and accessible. For security reasons, <a>you won't be able to view it again</a> through your Moneroo account. If you lose this secret key, you'll need to generate a new one.",
  "component.apiKey.expiresAt.note":
    "If you don’t set a date, the key will never expire.",

  "component.webhook.add": "Add webhook",
  "component.developer.addWebhook": "Add new webhook",
  "component.webhook.secret": "Secret hash",
  "component.emptyWebhook": "No webhook added yet",

  "component.webhook.delete": "Delete webhook",
  "component.webhook.deleteMessage":
    "Are you sure you want to delete this webhook? This action cannot be undone.",
  "component.webhook.deleted": "Webhook has been deleted successfully!",

  "component.apiKey.delete": "Delete API key",
  "component.apiKey.deleteMessage":
    "Are you sure you want to delete this api key ? This action cannot be undone.",

  "component.transaction.initiatedNotice":
    "More details will be available once the payment status has changed.",
  "component.createApp": "Create new App",

  "component.auditLogsTab.action": "Action",
  "component.auditLogsTab.details": "Action details",

  "component.transactionsTab.reference": "Reference",

  "component.oneMoreStep": "One more step",
  "component.oneMoreStep.description":
    "Please add the url of this webhook to your account. This will allow us to receive a notification from the gateway each time a transaction is made or an event occurs.",

  "component.no": "No",
  "component.yes": "Yes",
  "component.done": "Done",

  "component.didYouCopy": "Did you copy link?",
  "component.didYouCopy.description":
    "Please, make sure that you have added the link to your payment gateway. You can always find it in the settings of gateway.",
};
