import React from "react";
import { DateRange } from "@/types";
import {
  Box,
  DatePickerInput,
  Group,
  InputField,
  LoadingOverlay,
  Menu,
  UnstyledButton,
} from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  Calendar2Outline,
  ArrowDownOutline,
} from "@axazara/raiton-icons";
import { PaginatedRefills, User, useFilterDropdownStyles } from "utilities";
import { EmptyState, RefillsTable } from "@/components";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";
import { useGetUserRefills } from "../services";
import { PaginationControls } from "@/components/PaginationControls";

export function RefillsTab({ user }: { user: User }) {
  const { data: refillsData, isLoading } = useGetUserRefills(user.id, {
    page: 1,
  });

  if (isLoading) return <LoadingOverlay visible />;

  if (refillsData?.data && refillsData?.data.length > 0)
    return <RefillsTabRender refillsData={refillsData} user={user} />;

  return <EmptyState title="No transaction yet" />;
}

function RefillsTabRender({
  refillsData,
  user,
}: {
  refillsData: PaginatedRefills;
  user: User;
}) {
  const statusData = [
    { label: "All", value: "all", color: "bg-neutral-50" },
    { label: "Initiated", value: "initiated", color: "bg-primary-50" },
    { label: "Pending", value: "pending", color: "bg-secondary-50" },
    { label: "Completed", value: "completed", color: "bg-success-50" },
    { label: "Failed", value: "failed", color: "bg-critical-50" },
  ];

  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>([
    null,
    null,
  ]);

  const [openedStatusFilter, setOpenedStatusFilter] = React.useState(false);
  const { classes: StatusFilterClasses } = useFilterDropdownStyles({
    opened: openedStatusFilter,
    width: "20%",
  });

  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState<any>(statusData[0]);

  const { data: refills } = useGetUserRefills(user.id, {
    page: 1,
    search: debouncedSearch,
    initialData: refillsData,
    status: status.value,
    dateRange: selectedDateRange,
  });

  const statusItems = statusData.map((item) => (
    <Menu.Item
      icon={<Box className={`w-3 h-3 rounded-full ${item.color}`} />}
      onClick={() => setStatus(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Box className="px-6">
      <Box className="flex flex-row gap-4 mb-6">
        <InputField
          classNames={{ input: "rounded-xl" }}
          radius={12}
          placeholder="Search refills"
          size="xs"
          icon={<SearchNormal1Outline className="w-5 h-5" />}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full"
          value={searchQuery}
          rightSection={
            <Box>
              {debouncedSearch ? (
                <CloseCircleFill
                  className="w-4 h-4 text-neutral-40"
                  onClick={() => {
                    setSearchQuery("");
                  }}
                />
              ) : null}
            </Box>
          }
        />

        <Menu
          onOpen={() => setOpenedStatusFilter(true)}
          onClose={() => setOpenedStatusFilter(false)}
          radius="md"
          width="max-content"
          withinPortal
        >
          <Menu.Target>
            <UnstyledButton className={StatusFilterClasses.control}>
              <Group spacing="xs">
                <Box className={`w-3 h-3 rounded-full ${status?.color}`} />
                <span className={StatusFilterClasses.label}>
                  {status?.label}
                </span>
              </Group>
              <ArrowDownOutline
                className={classNames(
                  StatusFilterClasses.icon,
                  "w-4 h-4 text-neutral-40",
                )}
              />
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>{statusItems}</Menu.Dropdown>
        </Menu>

        <DatePickerInput
          size="xs"
          type="range"
          clearable
          icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
          value={selectedDateRange}
          // @ts-ignore
          placeholder="Filter by date"
          numberOfColumns={2}
          styles={(theme) => ({
            root: {
              "> div": {
                borderColor: theme.colors.neutral[8],
              },
            },
          })}
          onChange={setSelectedDateRange}
          allowSingleDateSelectionInRange
          rightSection={
            selectedDateRange[1] ? null : (
              <ArrowDownOutline className="w-4 h-4 text-neutral-40" />
            )
          }
        />
      </Box>

      <Box>
        {refills?.data && (
          <>
            <RefillsTable refills={refills?.data} />
            <PaginationControls
              page={page}
              pagination={refills?.pagination}
              onPageChange={setPage}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
