import {
  Box,
  Button,
  Checkbox,
  Divider,
  InputField,
  OTPInput,
  Text,
} from "@axazara/raiton-atoms";
import { AddOutline } from "@axazara/raiton-icons";
import { closeAllModals, closeModal, useForm } from "@axazara/raiton-molecules";
import { Bundle } from "utilities";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useUpdateBundle } from "../services";
import { useQueryClient } from "@tanstack/react-query";
import { ApiEndpoints } from "@/configs";

export function EditBundle({ bundle }: { bundle: Bundle }) {
  const queryClient = useQueryClient();

  const bundleDataForm = useForm<Partial<Bundle> & { otp_code: string }>({
    initialValues: {
      price: bundle.price,
      sale_price: bundle.sale_price,
      is_public: bundle.is_public,
      otp_code: "",
    },
  });

  const useUpdateBundleMutation = useUpdateBundle({ id: bundle.id });

  async function handleSubmit(values: Partial<Bundle>) {
    try {
      if (
        values.price !== bundle.price ||
        values.sale_price !== bundle.sale_price ||
        values.is_public !== bundle.is_public
      ) {
        const response = await useUpdateBundleMutation.mutateAsync(values);
        queryClient.invalidateQueries({ queryKey: [ApiEndpoints.bundles] });

        showSuccessNotification((response as any)?.message);
        closeModal("");
      }
    } catch (error) {
      showErrorNotification((error as any)?.message);
      closeModal("");
    }
  }

  return (
    <Box className="mt-12">
      <Box className="flex items-center space-x-36 mb-4">
        <Box className="flex flex-col space-y-2">
          <Text className="text-p-01 text-neutral-50">Currency</Text>
          <Text className="text-heading-06-semibold text-primary-40">
            {bundle.currency}
          </Text>
        </Box>

        <Box className="flex flex-col space-y-2">
          <Text className=" text-p-01 text-neutral-50">Coin</Text>
          <Text className=" text-heading-06-semibold text-primary-40">
            {bundle.coins}
          </Text>
        </Box>
      </Box>

      <Box
        component="form"
        onSubmit={bundleDataForm.onSubmit((values) => handleSubmit(values))}
      >
        <InputField
          {...bundleDataForm.getInputProps("price")}
          label="Normal price"
          classNames={{
            input: "rounded-xl",
            label: "mb-3 text-p-01-semibold text-dark",
          }}
          radius={12}
          placeholder="2000 FCFA"
          size="xs"
          className="w-full mb-4"
        />

        <InputField
          {...bundleDataForm.getInputProps("sale_price")}
          label="Sale price"
          classNames={{
            input: "rounded-xl",
            label: "mb-3 text-p-01-semibold text-dark",
          }}
          radius={12}
          placeholder="price"
          size="xs"
          className="w-full mb-4"
        />

        <Box className="flex items-center justify-between mb-6">
          <Text className="text-neutral-20 text-p-01-semibold"> Visible</Text>
          <Checkbox
            {...bundleDataForm.getInputProps("is_public")}
            size="lg"
            checked={bundleDataForm.values.is_public}
            icon={({ indeterminate, className }) =>
              indeterminate ? (
                <Box
                  className={className}
                  style={{ transform: "rotate(45deg)" }}
                >
                  <AddOutline className=" text-neutral-30 w-6 h-6" />
                </Box>
              ) : (
                <Box
                  className={`${className} flex items-center justify-center`}
                >
                  <img src="/assets/images/mark-white.svg" alt="mark_icons" />
                </Box>
              )
            }
          />
        </Box>
        <Divider color="neutral.8" className="w-full mb-6" />

        <Box className="flex flex-col mb-8">
          <Text className="text-center text-neutral-30 text-p-01 mb-4">
            Veuillez entrer le code de confirmation que vous voyez sur
            l’application d’authentification
          </Text>
          <Box className="flex justify-center items-center">
            <OTPInput
              radius={12}
              size="md"
              required
              onChange={(res) => {
                bundleDataForm.setFieldValue("otp_code", res);
              }}
              allowedCharacters="numeric"
            />
          </Box>
        </Box>

        <Box className="flex space-x-2">
          <Button
            onClick={() => closeAllModals()}
            variant="outline"
            color="error.4"
            className="text-p-01-semibold"
          >
            Cancel
          </Button>
          <Button
            fullWidth
            className="text-p-01-semibold"
            type="submit"
            disabled={!bundleDataForm.isDirty()}
            loading={useUpdateBundleMutation.isPending}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
