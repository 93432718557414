import { NavLink as RouterNavLink } from "react-router-dom";
import { NavLink, Text } from "@axazara/raiton-atoms";

interface SidebarLinkProps {
  to: string;
  icon: JSX.Element;
  labelKey: string;
  opened: boolean;
}

export const SidebarLink = ({
  to,
  icon,
  labelKey,
  opened,
}: SidebarLinkProps) => {
  const linkStyles = opened ? "justify-start" : "justify-center";

  return (
    <RouterNavLink to={to} className="block">
      {({ isActive }) => (
        <NavLink
          className={`hover:bg-primary-90 hover:text-primary-40 ${
            isActive ? "bg-primary-90 text-primary-40" : "text-neutral-30"
          } rounded-lg flex flex-row items-center ${linkStyles}`}
          icon={icon}
          label={
            opened && <Text className="text-caption-medium">{labelKey}</Text>
          }
          styles={() => ({
            icon: {
              marginRight: opened ? 12 : 0,
            },
            body: {
              display: opened ? "block" : "none",
            },
            label: {
              display: opened ? "block" : "none",
            },
          })}
        />
      )}
    </RouterNavLink>
  );
};
