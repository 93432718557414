import React from "react";
import {
  Avatar,
  Box,
  Button,
  Group,
  InputField,
  LoadingOverlay,
  Menu,
  Text,
  UnstyledButton,
} from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  ArrowDownOutline,
  AddCircleFill,
  DollarCircleOutline,
} from "@axazara/raiton-icons";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";
import { openModal } from "@axazara/raiton-molecules";
import {
  Country,
  ProviderAccount,
  Service,
  useFilterDropdownStyles,
} from "utilities";
import { EmptyState } from "@/components";
import {
  useGetPricingCountries,
  useGetPricingServices,
  useGetPricings,
  useGetProviders,
} from "../../services";
import { PaginationControls } from "@/components/PaginationControls";
import { AddPricing, PricingTable } from "../../components";
import { useSetPageMeta } from "@/hooks";

export function Pricing() {
  const { data: pricingData, isLoading } = useGetPricings({ page: 1 });

  useSetPageMeta({
    title: "Pricings",
    icon: DollarCircleOutline,
  });
  if (isLoading)
    return (
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ color: "secondary" }}
      />
    );

  if (pricingData && pricingData?.data?.length > 0)
    return <PricingPageRender />;

  return (
    <EmptyState
      title="No pricing yet"
      primaryAction={
        <Button
          onClick={() =>
            openModal({
              centered: true,
              children: <AddPricing />,
              size: "md",
              title: (
                <Text className="text-heading-05-bold text-neutral-30">
                  Add pricing
                </Text>
              ),
            })
          }
          size="xs"
          rightIcon={<AddCircleFill className="w-5 h-5" />}
          className="text-p-01-semibold"
        >
          Add pricing
        </Button>
      }
    />
  );
}

function PricingPageRender() {
  const [page, setPage] = React.useState(1);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const [countryOpened, setCountryOpened] = React.useState(false);
  const [serviceOpened, setServiceOpened] = React.useState(false);
  const [providerOpened, setProviderOpened] = React.useState(false);

  const { classes: countryClasses } = useFilterDropdownStyles({
    opened: countryOpened,
    width: "50%",
  });
  const { classes: serviceClasses } = useFilterDropdownStyles({
    opened: serviceOpened,
    width: "50%",
  });
  const { classes: providerClasses } = useFilterDropdownStyles({
    opened: providerOpened,
    width: "50%",
  });

  const [searchCountryQuery, setSearchCountryQuery] = React.useState("");
  const [debouncedCountrySearch] = useDebouncedValue(searchCountryQuery, 500);

  const [searchServiceQuery, setSearchServiceQuery] = React.useState("");
  const [debouncedServiceSearch] = useDebouncedValue(searchServiceQuery, 500);

  const [searchProviderQuery, setSearchProviderQuery] = React.useState("");
  const [debouncedProviderSearch] = useDebouncedValue(searchProviderQuery, 500);

  const [countryFilter, setCountryFilter] = React.useState<Country | null>();
  const [serviceFilter, setServiceFilter] = React.useState<Service | null>();
  const [providerFilter, setProviderFilter] =
    React.useState<ProviderAccount | null>();

  const { data: countries } = useGetPricingCountries({
    page,
    search: debouncedCountrySearch,
  });
  const { data: services } = useGetPricingServices({
    page,
    search: debouncedServiceSearch,
  });
  const { data: providers } = useGetProviders({
    page,
    search: debouncedProviderSearch,
  });

  const { data: pricings, refetch } = useGetPricings({
    page,
    search: debouncedSearch,
    country: countryFilter?.code,
    service: serviceFilter?.short_code,
    provider_id: providerFilter?.id,
  });

  const countryItems = countries?.map((item) => (
    <Menu.Item
      className={countryFilter?.name === item.name ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={item?.flag} />}
      onClick={() => setCountryFilter(item)}
      key={item?.name}
    >
      {item?.name}
    </Menu.Item>
  ));

  const serviceItems = services?.map((item) => (
    <Menu.Item
      className={serviceFilter?.name === item.name ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={item?.icon_url} />}
      onClick={() => setServiceFilter(item)}
      key={item?.name}
    >
      {item?.name}
    </Menu.Item>
  ));

  const providerItems = providers?.data?.map((item) => (
    <Menu.Item
      className={providerFilter?.name === item.name ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={item?.provider?.icon_url} />}
      onClick={() => setProviderFilter(item)}
      key={item?.name}
    >
      {item?.name}
    </Menu.Item>
  ));

  return (
    <Box className="pt-4">
      <Box className="flex flex-row w-full sticky top-0 z-10 bg-white py-5">
        <Box className="flex w-full md:flex-row md:space-x-2">
          <Box className="flex w-full md:flex-row md:space-x-2">
            <InputField
              classNames={{ input: "rounded-xl" }}
              className="w-full"
              radius={12}
              placeholder="Search pricing"
              size="xs"
              icon={<SearchNormal1Outline className="w-5 h-5" />}
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
              rightSection={
                <Box>
                  {debouncedSearch ? (
                    <CloseCircleFill
                      className="w-4 h-4 text-neutral-40"
                      onClick={() => {
                        setSearchQuery("");
                      }}
                    />
                  ) : null}
                </Box>
              }
            />
            <Box></Box>

            <Menu
              onOpen={() => setCountryOpened(true)}
              onClose={() => setCountryOpened(false)}
              radius="md"
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton
                  className={`${countryClasses.control} space-x-4`}
                >
                  {countryOpened ? (
                    <InputField
                      onClick={(e) => e.stopPropagation()}
                      styles={() => ({})}
                      classNames={{
                        input:
                          "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                      }}
                      value={searchCountryQuery}
                      className="w-full"
                      iconWidth={12}
                      size="xs"
                      onChange={(e) => setSearchCountryQuery(e.target.value)}
                      icon={<SearchNormal1Outline className="w-3 h-3" />}
                    />
                  ) : countryFilter ? (
                    <>
                      <Group spacing="xs" className="w-full">
                        {countryFilter?.flag && (
                          <Avatar size={24} src={countryFilter?.flag} />
                        )}
                        <span
                          className={`${countryClasses.label} text-ellipsis overflow-hidden whitespace-nowrap w-16`}
                        >
                          {countryFilter?.name}
                        </span>
                      </Group>
                      {countryFilter && (
                        <CloseCircleFill
                          className="w-4 h-4 text-neutral-50 mr-2"
                          onClick={() => {
                            setCountryFilter(null);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text
                      className="text-footer"
                      styles={() => ({ root: { fontSize: 12 } })}
                    >
                      Filter by country
                    </Text>
                  )}
                  <ArrowDownOutline
                    className={classNames(
                      countryClasses.icon,
                      "w-4 h-4 text-neutral-40",
                    )}
                  />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown style={{ maxHeight: "300px", overflow: "auto" }}>
                {countryItems}
              </Menu.Dropdown>
            </Menu>

            <Menu
              onOpen={() => setServiceOpened(true)}
              onClose={() => setServiceOpened(false)}
              radius="md"
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton
                  className={`${serviceClasses.control} space-x-4`}
                >
                  {serviceOpened ? (
                    <InputField
                      onClick={(e) => e.stopPropagation()}
                      styles={() => ({})}
                      classNames={{
                        input:
                          "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                      }}
                      value={searchServiceQuery}
                      className="w-full"
                      iconWidth={12}
                      size="xs"
                      onChange={(e) => setSearchServiceQuery(e.target.value)}
                      icon={<SearchNormal1Outline className="w-3 h-3" />}
                    />
                  ) : serviceFilter ? (
                    <>
                      <Group spacing="xs" className="w-full">
                        {serviceFilter?.icon_url && (
                          <Avatar size={24} src={serviceFilter?.icon_url} />
                        )}
                        <span
                          className={`${serviceClasses.label} text-ellipsis overflow-hidden whitespace-nowrap w-16`}
                        >
                          {serviceFilter?.name}
                        </span>
                      </Group>
                      {serviceFilter && (
                        <CloseCircleFill
                          className="w-4 h-4 text-neutral-50 mr-2"
                          onClick={() => {
                            setServiceFilter(null);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text
                      className="text-footer"
                      styles={() => ({ root: { fontSize: 12 } })}
                    >
                      Filter by service
                    </Text>
                  )}
                  <ArrowDownOutline
                    className={classNames(
                      serviceClasses.icon,
                      "w-4 h-4 text-neutral-40",
                    )}
                  />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown style={{ maxHeight: "300px", overflow: "auto" }}>
                {serviceItems}
              </Menu.Dropdown>
            </Menu>
            <Menu
              onOpen={() => setProviderOpened(true)}
              onClose={() => setProviderOpened(false)}
              radius="md"
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton
                  className={`${providerClasses.control} space-x-4`}
                >
                  {providerOpened ? (
                    <InputField
                      onClick={(e) => e.stopPropagation()}
                      styles={() => ({})}
                      classNames={{
                        input:
                          "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                      }}
                      value={searchProviderQuery}
                      className="w-full"
                      iconWidth={12}
                      size="xs"
                      onChange={(e) => setSearchProviderQuery(e.target.value)}
                      icon={<SearchNormal1Outline className="w-3 h-3" />}
                    />
                  ) : providerFilter ? (
                    <>
                      <Group spacing="xs" className="w-full">
                        {providerFilter?.provider?.icon_url && (
                          <Avatar
                            size={24}
                            src={providerFilter?.provider?.icon_url}
                          />
                        )}
                        <span
                          className={`${providerClasses.label} text-ellipsis overflow-hidden whitespace-nowrap w-16`}
                        >
                          {providerFilter?.name}
                        </span>
                      </Group>
                      {providerFilter && (
                        <CloseCircleFill
                          className="w-4 h-4 text-neutral-50 mr-2"
                          onClick={() => {
                            setProviderFilter(null);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text
                      className="text-footer"
                      styles={() => ({ root: { fontSize: 12 } })}
                    >
                      Filter by provider
                    </Text>
                  )}
                  <ArrowDownOutline
                    className={classNames(
                      providerClasses.icon,
                      "w-4 h-4 text-neutral-40",
                    )}
                  />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown style={{ maxHeight: "300px", overflow: "auto" }}>
                {providerItems}
              </Menu.Dropdown>
            </Menu>
          </Box>

          <Box>
            <Button
              onClick={() =>
                openModal({
                  centered: true,
                  children: <AddPricing />,
                  size: "md",
                  title: (
                    <Text className="text-heading-05-bold text-neutral-30">
                      Add pricing
                    </Text>
                  ),
                })
              }
              size="xs"
              rightIcon={<AddCircleFill className="w-5 h-5" />}
              className="text-p-01-semibold"
            >
              Add pricing
            </Button>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box className="relative">
          <LoadingOverlay visible={true} loaderProps={{ color: "secondary" }} />
        </Box>
        {pricings?.data && (
          <PricingTable pricings={pricings.data} refetch={refetch} />
        )}

        <Box>
          {pricings?.data && (
            <PaginationControls
              page={page}
              pagination={pricings?.pagination}
              onPageChange={setPage}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
