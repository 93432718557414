import { Box, LoadingOverlay } from "@axazara/raiton-atoms";

import {
  EmptyState,
  OrdersFilter,
  OrdersTable,
  PaginationControls,
} from "@/components";
import { User } from "utilities";

import { useGetUserOrders } from "@/features";
import { useUserOrders } from "../pages/Users/useUserOrders";

export function OrdersTab({ user }: { user: User }) {
  const { data: userOrdersData, isLoading } = useGetUserOrders(user.id, {
    page: 1,
  });

  if (isLoading) return <LoadingOverlay visible />;

  if (userOrdersData?.data && userOrdersData?.data.length > 0) {
    return <OrderPageRender id={user.id} />;
  }

  return <EmptyState title="No orders yet" />;
}

export function OrderPageRender({ id }: { id: string }) {
  const {
    // Data and loading states
    orders,
    isLoading,
    countries,
    services,

    // Search states and setters
    setSearchQuery,
    setSearchCountryQuery,
    setSearchServiceQuery,
    searchQuery,
    debouncedSearch,
    searchCountryQuery,
    searchServiceQuery,

    // Filter states and setters
    setSelectedStatus,
    setSelectedCountry,
    setSelectedService,
    setSelectedDateRange,

    // Dropdown open states and setters
    setOpenedStatusFilter,
    setOpenedCountryFilter,
    setOpenedServiceFilter,

    // Pagination
    page,
    setPage,

    // URL Params
    // urlParams,
    setUrlParams,

    // Static and selected filter states
    statusData,
    selectedStatus,
    selectedCountry,
    selectedService,
    openedStatusFilter,
    openedCountryFilter,
    openedServiceFilter,
    selectedDateRange,

    searchProviderQuery,
    setSearchProviderQuery,
    providers,
    selectedProvider,
    setSelectedProvider,
    openedProviderFilter,
    setOpenedProviderFilter,
  } = useUserOrders(id);

  return (
    <Box>
      <Box>
        <Box className="relative">
          <LoadingOverlay visible={isLoading} />
          <Box className="py-5 flex flex-row w-full justify-between sticky top-0 bg-white z-10">
            <OrdersFilter
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              debouncedSearch={debouncedSearch}
              searchCountryQuery={searchCountryQuery}
              setSearchCountryQuery={setSearchCountryQuery}
              searchServiceQuery={searchServiceQuery}
              setSearchServiceQuery={setSearchServiceQuery}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              statusData={statusData}
              openedStatusFilter={openedStatusFilter}
              setOpenedStatusFilter={setOpenedStatusFilter}
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              countries={countries}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              openedCountryFilter={openedCountryFilter}
              setOpenedCountryFilter={setOpenedCountryFilter}
              services={services}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              openedServiceFilter={openedServiceFilter}
              setOpenedServiceFilter={setOpenedServiceFilter}
              setUrlParams={setUrlParams}
              searchProviderQuery={searchProviderQuery}
              setSearchProviderQuery={setSearchProviderQuery}
              providers={providers}
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              openedProviderFilter={openedProviderFilter}
              setOpenedProviderFilter={setOpenedProviderFilter}
            />
          </Box>
          <Box className="relative">
            {orders?.data && (
              <>
                <OrdersTable orders={orders.data} />
                <PaginationControls
                  page={page}
                  pagination={orders.pagination}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
