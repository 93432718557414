import React from "react";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";
import {
  Box,
  DatePickerInput,
  Group,
  InputField,
  LoadingOverlay,
  Menu,
  UnstyledButton,
} from "@axazara/raiton-atoms";
import { User, useFilterDropdownStyles } from "utilities";
import { EmptyState, TransactionsTable } from "@/components";

import {
  Arrow3Outline,
  SearchNormal1Outline,
  CloseCircleFill,
  ArrowDown1Outline,
  ArrowUpOutline,
  ArrowDownOutline,
  Calendar2Outline,
} from "@axazara/raiton-icons";
import { DateRange } from "@/types";
import { PaginationControls } from "@/components/PaginationControls";
import { useGetUserTransactions } from "@/features";

export function TransactionsTab({ user }: { user: User }) {
  const { data: transactionsData, isLoading } = useGetUserTransactions(
    user.id,
    { page: 1 },
  );

  if (isLoading) return <LoadingOverlay visible />;

  if (transactionsData?.data && transactionsData?.data.length > 0)
    return <TransactionsTabRenderer id={user?.id} />;

  return <EmptyState title="No transactions yet" />;
}

function TransactionsTabRenderer({ id }: { id: string }) {
  const typeData = [
    {
      label: "All",
      value: "all",
      icon: (
        <Box className="text-neutral-40 bg-neutral w-6 h-6 p-1 rounded-full flex items-center justify-center">
          <Arrow3Outline className="w-3 h-3" />
        </Box>
      ),
    },
    {
      label: "Credit",
      value: "credit",
      icon: (
        <Box className="text-success-40 bg-success w-6 h-6 p-1 rounded-full flex items-center justify-center">
          <ArrowDown1Outline className="w-3 h-3" />
        </Box>
      ),
    },
    {
      label: "Debit",
      value: "debit",
      icon: (
        <Box className="text-critical-40 bg-critical w-6 h-6 p-1 rounded-full flex items-center justify-center">
          <ArrowUpOutline className="w-3 h-3" />
        </Box>
      ),
    },
  ];

  const [opened, setOpened] = React.useState(false);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>([
    null,
    null,
  ]);

  const { classes } = useFilterDropdownStyles({ opened, width: "15%" });

  const [page, setPage] = React.useState(1);
  const [type, setType] = React.useState<any>(typeData[0]);

  const { data: transactions, isLoading } = useGetUserTransactions(id, {
    page,
    search: debouncedSearch,
    type: type.value,
    dateRange: selectedDateRange,
  });

  const typeItems = typeData.map((item) => (
    <Menu.Item icon={item.icon} onClick={() => setType(item)} key={item.label}>
      {item.label}
    </Menu.Item>
  ));

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="flex flex-row gap-4 mb-6">
        <InputField
          classNames={{ input: "rounded-xl" }}
          radius={12}
          placeholder="Search transaction"
          size="xs"
          icon={<SearchNormal1Outline className="w-5 h-5" />}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full"
          value={searchQuery}
          rightSection={
            <Box>
              {debouncedSearch ? (
                <CloseCircleFill
                  className="w-4 h-4 text-neutral-40"
                  onClick={() => {
                    setSearchQuery("");
                  }}
                />
              ) : null}
            </Box>
          }
        />

        <Menu
          onOpen={() => setOpened(true)}
          onClose={() => setOpened(false)}
          radius="md"
          withinPortal
        >
          <Menu.Target>
            <UnstyledButton className={classes.control}>
              <Group spacing="xs">
                {type?.icon}
                <span className={classes.label}>{type?.label}</span>
              </Group>
              <ArrowDownOutline
                className={classNames(classes.icon, "w-4 h-4 text-neutral-40")}
              />
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>{typeItems}</Menu.Dropdown>
        </Menu>
        <DatePickerInput
          size="xs"
          type="range"
          clearable
          icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
          value={selectedDateRange}
          numberOfColumns={2}
          styles={(theme) => ({
            root: {
              "> div": {
                borderColor: theme.colors.neutral[8],
              },
            },
          })}
          onChange={setSelectedDateRange}
          allowSingleDateSelectionInRange
          rightSection={
            selectedDateRange[1] ? null : (
              <ArrowDownOutline className="w-4 h-4 text-neutral-40" />
            )
          }
        />
      </Box>
      <Box>
        {transactions?.data && (
          <>
            <TransactionsTable transactions={transactions?.data} />
            <PaginationControls
              page={page}
              pagination={transactions?.pagination}
              onPageChange={setPage}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
