import React from "react";
import { Box, InputField, LoadingOverlay } from "@axazara/raiton-atoms";
import { SearchNormal1Outline, CloseCircleFill } from "@axazara/raiton-icons";
import { useDebouncedValue } from "@axazara/raiton-utils";
import { EmptyState } from "@/components";
import { useGetProviders } from "@/features/ProviderAccounts/services/api";
import { ProviderAccountCard } from "../../components/ProviderAccountCard";

export function AddProviderAccounts() {
  const { data: providersData, isError, isLoading } = useGetProviders({});
  if (isError) return <Box>Error</Box>;
  if (isLoading) return <LoadingOverlay visible />;
  if (providersData && providersData.length > 0)
    return <AddProviderAccountsRender />;

  return <EmptyState title="" description="" />;
}

export function AddProviderAccountsRender() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const { data: providers, isLoading } = useGetProviders({
    search: debouncedSearch,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="pt-4">
        <Box className="mb-6">
          <InputField
            classNames={{ input: "rounded-xl" }}
            radius={12}
            placeholder="Search provider account"
            size="xs"
            icon={<SearchNormal1Outline className="w-5 h-5" />}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full"
            value={searchQuery}
            rightSection={
              <Box>
                {debouncedSearch ? (
                  <CloseCircleFill
                    className="w-4 h-4 text-neutral-40"
                    onClick={() => {
                      setSearchQuery("");
                    }}
                  />
                ) : null}
              </Box>
            }
          />
        </Box>
        <Box>{providers && <ProviderAccountCard providers={providers} />}</Box>
      </Box>
    </Box>
  );
}
