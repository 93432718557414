import {
  Box,
  Text,
  Tag,
  Surface,
  Divider,
  LoadingOverlay,
} from "@axazara/raiton-atoms";
import {
  Profile2userOutline,
  Wallet3Outline,
  WalletCheckOutline,
} from "@axazara/raiton-icons";
import { User, formatDate } from "utilities";
import { useGetSummary } from "@/features/Users/services";

export function UserInformationTab({ user }: { user: User }) {
  const { data: summary, isLoading } = useGetSummary(user.id);
  if (isLoading) return <LoadingOverlay visible />;

  return (
    <Box className="px-8">
      <Surface radius={16} className="bg-white">
        <Box className="flex gap-6 mb-8">
          <Surface radius={16} className="bg-success w-full p-6">
            <Box className="bg-neutral-10 mb-2 rounded-full w-11 h-11 flex justify-center items-center">
              <WalletCheckOutline className="w-5 h-5 text-white" />
            </Box>
            <Text className="text-p-01-medium mb-2">Sold Available</Text>
            <Text component="span" className="text-heading-05-bold">
              {summary?.wallet?.balance}
            </Text>
          </Surface>
          <Surface radius={16} className="bg-primary w-full p-6">
            <Box className="bg-neutral-10 rounded-full w-11 h-11 flex justify-center items-center mb-2">
              <Wallet3Outline className="w-5 h-5 text-white" />
            </Box>
            <Text className="text-p-01-medium mb-2">Total Orders</Text>
            <Text component="span" className="text-heading-05-bold">
              {summary?.total?.orders?.all}
            </Text>
          </Surface>
          <Surface radius={16} className="bg-secondary w-full p-6">
            <Box className="bg-neutral-10 rounded-full w-11 h-11 flex justify-center items-center mb-2">
              <Profile2userOutline className="w-5 h-5 text-white" />
            </Box>
            <Text className="text-p-01-medium mb-2">Total Emails</Text>
            <Text component="span" className="text-heading-05-bold">
              {summary?.total?.emails}
            </Text>
          </Surface>
        </Box>
      </Surface>
      <Divider color="neutral.7" className={"border-neutral-90 mb-8"} />
      <Box className="mb-36 mx-auto max-w-2xl">
        <Box className="space-y-4">
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">
              ID
            </Text>
            <Text className="text-neutral-30 text-p-01">{user?.id}</Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">
              First Name
            </Text>
            <Text className="text-neutral-30 text-p-01">
              {user?.first_name}
            </Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">
              Last Name
            </Text>
            <Text className="text-neutral-30 text-p-01">{user?.last_name}</Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">
              Email
            </Text>
            <Text className="text-neutral-30 font-semibold text-p-01">
              {user?.email}
            </Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">
              Sign up at
            </Text>
            <Text className="text-neutral-30 text-p-01">
              {formatDate(user?.created_at, "en", "D MMM YYYY, HH:mm")}
            </Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">
              last login at
            </Text>
            <Text className="text-neutral-30 text-p-01">
              {formatDate(user?.last_login_at, "en", "D MMM YYYY, HH:mm")}
            </Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">
              Identity status
            </Text>
            {user?.identity_is_verified ? (
              <Tag
                className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
                size="lg"
              >
                <Text
                  component="span"
                  className="text-success-30 text-p-01-semibold"
                >
                  Verified
                </Text>
              </Tag>
            ) : (
              <Tag
                className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
                size="lg"
              >
                <Text
                  component="span"
                  className="text-success-30 text-p-01-semibold"
                >
                  Unverified
                </Text>
              </Tag>
            )}
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">
              Two Factors statuts
            </Text>
            <Box>
              {user?.identity_is_verified ? (
                <Tag
                  className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
                  size="lg"
                >
                  <Text
                    component="span"
                    className="text-success-30 text-p-01-semibold"
                  >
                    Enable
                  </Text>
                </Tag>
              ) : (
                <Tag
                  className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3"
                  size="lg"
                >
                  <Text
                    component="span"
                    className="text-critical-30 text-p-01-semibold"
                  >
                    Disable
                  </Text>
                </Tag>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
