import { useState, useEffect } from "react";
import { useDynamicURLSearchParams } from "@/hooks";
import { Country, ProviderAccount, Service, formatDate } from "utilities";
import { DateRange } from "@/types";
import { useDebouncedValue, buildFetchParams } from "@axazara/raiton-utils";
import {
  useGetCountries,
  useGetProviders,
  useGetServices,
  useGetUserOrders,
} from "@/features";

export interface StatusType {
  label: string;
  value: string;
  color: string;
}

export const useUserOrders = (id: string) => {
  // Static data
  const statusData = [
    { label: "All", value: "all", color: "bg-neutral-50" },
    { label: "Initiated", value: "initiated", color: "bg-primary-50" },
    { label: "Pending", value: "pending", color: "bg-secondary-50" },
    { label: "Completed", value: "completed", color: "bg-success-50" },
    { label: "Failed", value: "failed", color: "bg-critical-50" },
    { label: "Expired", value: "expired", color: "bg-critical-50" },
    { label: "Refund", value: "refunded", color: "bg-secondary-50" },
  ];

  const { params: urlParams, setParams: setUrlParams } =
    useDynamicURLSearchParams<{
      search: string;
      page: string;
      status: string;
      from: string;
      to: string;
      currency: string;
    }>({
      search: "",
      page: "1",
      status: "all",
      from: "",
      to: "",
      currency: "",
    });

  // State for filter dropdowns
  const [openedStatusFilter, setOpenedStatusFilter] = useState(false);
  const [openedCountryFilter, setOpenedCountryFilter] = useState(false);
  const [openedServiceFilter, setOpenedServiceFilter] = useState(false);
  const [openedProviderFilter, setOpenedProviderFilter] = useState(false);

  // State for search queries
  const [searchQuery, setSearchQuery] = useState(urlParams.search);
  const [searchCountryQuery, setSearchCountryQuery] = useState("");
  const [searchServiceQuery, setSearchServiceQuery] = useState("");
  const [searchProviderQuery, setSearchProviderQuery] = useState("");

  // Debounced search queries
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [debouncedCountrySearch] = useDebouncedValue(searchCountryQuery, 500);
  const [debouncedServiceSearch] = useDebouncedValue(searchServiceQuery, 500);
  const [debouncedProviderSearch] = useDebouncedValue(searchProviderQuery, 500);

  // State for selected filters
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>([
    null,
    null,
  ]);
  const [selectedStatus, setSelectedStatus] = useState<StatusType | null>(
    statusData.find((status) => status.value === urlParams.status) ||
      statusData[0],
  );
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [selectedProvider, setSelectedProvider] =
    useState<ProviderAccount | null>(null);

  const [page, setPage] = useState(1);

  // Fetching data

  const { data: countries } = useGetCountries({
    page,
    search: debouncedCountrySearch,
  });
  const { data: services } = useGetServices({
    page,
    search: debouncedServiceSearch,
  });

  const { data: providers } = useGetProviders({
    page,
    search: debouncedProviderSearch,
  });

  const { data: orders, isLoading } = useGetUserOrders(id, {
    page,
    search: debouncedSearch,
    status: selectedStatus?.value || "all",
    country: selectedCountry?.code,
    service: selectedService?.short_code,
    dateRange: selectedDateRange,
    provider_id: selectedProvider?.id,
  });

  useEffect(() => {
    const [fromDate, toDate] = selectedDateRange;

    const formattedParams = buildFetchParams({
      country: selectedCountry?.code ?? "",
      service: selectedService?.short_code ?? "",
      search: debouncedSearch,
      provider_id: selectedProvider?.provider?.id,
      status: selectedStatus?.value,
      page: page.toString(),
      from:
        fromDate && toDate
          ? formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD")
          : "",
      to:
        toDate && formatDate
          ? formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD")
          : "",
    });

    setUrlParams(formattedParams);
  }, [debouncedSearch, selectedStatus, page, selectedDateRange]);

  useEffect(() => {
    const [fromDate, toDate] = selectedDateRange;

    if (!fromDate || !toDate) setUrlParams({ to: "", from: "" });
  }, [selectedDateRange]);

  return {
    // Data and loading states
    orders,
    isLoading,
    countries,
    services,

    // Search states and setters
    setSearchQuery,
    setSearchCountryQuery,
    setSearchServiceQuery,

    debouncedSearch,
    searchQuery,
    searchServiceQuery,
    searchCountryQuery,
    debouncedCountrySearch,
    debouncedServiceSearch,

    // Filter states and setters
    setSelectedStatus,
    setSelectedService,
    setSelectedCountry,
    setSelectedDateRange,

    // Dropdown open states and setters
    setOpenedStatusFilter,
    setOpenedServiceFilter,
    setOpenedCountryFilter,

    // Pagination
    page,
    setPage,

    // URL Params
    urlParams,
    setUrlParams,

    // Static and selected filter states
    statusData,
    selectedCountry,
    selectedService,
    openedStatusFilter,
    openedCountryFilter,
    openedServiceFilter,
    selectedStatus,
    selectedDateRange,

    searchProviderQuery,
    setSearchProviderQuery,
    providers,
    selectedProvider,
    setSelectedProvider,
    openedProviderFilter,
    setOpenedProviderFilter,
  };
};
