import { Box, Text, InputField, Select, Button } from "@axazara/raiton-atoms";
import {
  FrameFill,
  SmsFill,
  ShieldSecurityFill,
  ProfileAddFill,
} from "@axazara/raiton-icons";
import { closeModal, useForm } from "@axazara/raiton-molecules";
import { useQueryClient } from "@tanstack/react-query";
import { useCreateStaff } from "../services";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { roles } from "@/configs/roles";
import { Staff } from "utilities";

export function AddStaff() {
  const queryClient = useQueryClient();
  const addStaffForm = useForm<Partial<Staff>>({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      role: "staff",
    },
  });

  const addStaffMutation = useCreateStaff();

  async function handleStaffAdd(values: Partial<Staff>) {
    try {
      const response = await addStaffMutation.mutateAsync(values);
      queryClient.invalidateQueries();

      showSuccessNotification((response as any)?.message);
      closeModal("");
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  return (
    <Box>
      <Text className="text-center text-heading-05-bold text-neutral-30">
        Add Staff member
      </Text>
      <Box className="mb-8">
        <Box component="form" onSubmit={addStaffForm.onSubmit(handleStaffAdd)}>
          <Box className="space-y-6">
            <InputField
              required
              size="xs"
              label="First Name"
              icon={<FrameFill className="w-5 h-5 text-neutral-40" />}
              {...addStaffForm.getInputProps("first_name")}
              placeholder="Your first name"
            />
            <InputField
              required
              size="xs"
              label="Last Name"
              icon={<FrameFill className="w-5 h-5 text-neutral-40" />}
              {...addStaffForm.getInputProps("last_name")}
              placeholder="Your last name"
            />
            <InputField
              required
              type="email"
              size="xs"
              label="Email"
              icon={<SmsFill className="w-5 h-5 text-neutral-40" />}
              {...addStaffForm.getInputProps("email")}
              placeholder="Your email"
            />
            <Select
              {...addStaffForm.getInputProps("role")}
              data={roles}
              label="Role"
              type="text"
              required
              size="xs"
              radius={12}
              icon={<ShieldSecurityFill className="w-5 h-5 text-neutral-40" />}
            />
          </Box>
          <Box className="flex justify-center mt-4">
            <Button
              type="submit"
              size="xs"
              disabled={!addStaffForm.isDirty()}
              loading={addStaffMutation.isPending}
              className="text-caption-semibold"
              leftIcon={
                <ProfileAddFill className="w-6 h-6 text-caption-semibold" />
              }
            >
              Add member
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
