import { buildFetchParams } from "@axazara/raiton-utils";
import { useDelete, useGet, usePost, usePut } from "@/lib";
import { ApiEndpoints } from "@/configs";
import {
  PaginatedStaffs,
  PaginatedInvitations,
  PaginatedActivities,
  Activity,
  Staff,
} from "@/models";
import { formatDate } from "utilities";
import { DateRange } from "@/types";

export const useGetStaffsData = ({
  page = 1,
  search = "",
}: {
  page: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useGet<PaginatedStaffs>(`${ApiEndpoints.staffs}`, params);
};

export const useGetInvitations = ({
  page,
  search = "",
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useGet<PaginatedInvitations>(`${ApiEndpoints.invitations}`, params);
};

export const useGetActivities = ({
  page = 1,
  search = "",
  dateRange = [null, null],
}: {
  page?: number;
  search?: string;
  dateRange?: DateRange;
}) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    page,
    search,
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
  });

  return useGet<PaginatedActivities>(ApiEndpoints.activities, params);
};

export const useGetActivity = (id: string) =>
  useGet<Activity>(`${ApiEndpoints.activities}/${id}`);

export const useGetStaffActivities = (
  id: string,
  {
    page = 1,
    dateRange = [null, null],
    search = "",
  }: { page?: number; dateRange?: DateRange; search?: string },
) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    page,
    search,
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
  });

  return useGet<PaginatedActivities>(
    `${ApiEndpoints.staffs}/${id}/activities`,
    params,
  );
};

export const useCreateStaff = () =>
  usePost<Partial<Staff>, Staff>(ApiEndpoints.invitations);

export const useUpdateStaffProfile = (id: string) =>
  usePut<Partial<Staff>, Staff>(`${ApiEndpoints.staffs}/${id}`);

export const useResetStaffOTP = (id: string) =>
  usePost<{ otp_code: string }, { message: string }>(
    `${ApiEndpoints.staffs}/${id}/reset-two-factor`,
  );

export const useGetStaff = (id: string) =>
  useGet<Staff>(`${ApiEndpoints.staffs}/${id}`);

export const useDeleteInvitation = (id: string) =>
  useDelete(`${ApiEndpoints.invitations}/${id}`);

export const useResendInvitation = (id: string) =>
  usePost(`${ApiEndpoints.invitations}/${id}/resend`);
