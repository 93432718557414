export const appPaths = {
  root: "/",
  auth: "/auth",
  login: { path: "/auth/login", name: "login" },
  invitation: { path: "/auth/invitation/:token", name: "invitation" },

  users: { path: "/users", name: "users" },
  userDetails: { path: "/users/:id", name: "user-details" },

  orders: { path: "/orders", name: "orders" },
  orderDetails: { path: "/orders/:id", name: "order-details" },
  faq: { path: "/faq", name: "faq" },

  bundle: { path: "/bundle", name: "bundle" },

  providerAccount: { path: "/provider-account", name: "provider-account" },
  addProvider: {
    path: "/provider-account/add-provider-account",
    name: "add-provider-account",
  },
  addProviderForm: {
    path: "/provider-account/add-provider-account/:code",
    name: "add-provider-form",
  },
  providerSucceed: {
    path: "/provider-account/add/:code/success",
    name: "provider-succed",
  },

  discountCodes: { path: "/discount-codes", name: "discount-codes" },

  refills: { path: "/refills", name: "refills" },
  refillDetails: { path: "/refills/:id", name: "refill-details" },

  transactions: { path: "/transactions", name: "transactions" },
  services: { path: "/services", name: "services" },
  countries: { path: "/countries", name: "countries" },
  pricing: { path: "/pricing", name: "pricing" },

  staffs: { path: "/staffs", name: "staffs" },
  viewStaff: { path: "/staffs/:id", name: "staff-view" },
};
