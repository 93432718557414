import { PaginatedData, PaginatedType, Pagination } from "./pagination";
import { SimplifiedUser } from "./user";

export enum StaffPermissions {
  CREATE_STAFFS = "create.staffs",
  UPDATE_STAFFS = "update.staffs",
  READ_STAFFS = "read.staffs",
  READ_USERS = "read.users",
  READ_TRANSACTIONS = "read.transactions",
  CREATE_BUNDLES = "create.bundles",
  UPDATE_BUNDLES = "update.bundles",
  READ_BUNDLES = "read.bundles",
  DELETE_BUNDLES = "delete.bundles",
  CREATE_DISCOUNT_CODES = "create.discount_codes",
  UPDATE_DISCOUNT_CODES = "update.discount_codes",
  READ_DISCOUNT_CODES = "read.discount_codes",
  DELETE_DISCOUNT_CODES = "delete.discount_codes",
  CREATE_DOMAINS = "create.domains",
  UPDATE_DOMAINS = "update.domains",
  READ_DOMAINS = "read.domains",
  DELETE_DOMAINS = "delete.domains",
  CREATE_FAQS = "create.faqs",
  UPDATE_FAQS = "update.faqs",
  READ_FAQS = "read.faqs",
  DELETE_FAQS = "delete.faqs",
  CREATE_PRICING = "create.pricing",
  UPDATE_PRICING = "update.pricing",
  READ_PRICING = "read.pricing",
  DELETE_PRICING = "delete.pricing",
  CREATE_PROVIDERS = "create.providers",
  UPDATE_PROVIDERS = "update.providers",
  READ_PROVIDERS = "read.providers",
  READ_TOP_UP = "read.top_up",
  READ_ORDERS = "read.orders",
  UPDATE_ORDERS = "update.orders",
}

export interface Staff {
  id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  role: string;
  email: string;
  is_active: boolean;
  created_at: string;
  profile_photo_url: string;
  updated_at: string;
  permissions: StaffPermissions[];
}

export interface Activity {
  id: string;
  staff: Staff;
  action: string;
  description: string;
  ip: string;
  user_agent: string;
  created_at: string;
  updated_at: string;
  data: Staff[];
}
export interface PaginatedActivities {
  data: Activity[];
  pagination: Pagination;
}

export interface Invitation {
  id: string;
  staff: Staff;
  invite_by: SimplifiedUser;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  status: string;
  is_accepted: boolean;
  created_at: string;
  updated_at: string;
}

export interface Invitations {
  data: Invitation[];
  pagination: Pagination;
}
export type Staffs = Staff[];

export type PaginatedStaffs = PaginatedType<PaginatedData, "data", Staffs>;
