import { Avatar, Box, Button, Divider, Text } from "@axazara/raiton-atoms";
import { ArrowRightOutline } from "@axazara/raiton-icons";
import { Link } from "react-router-dom";

export function ProviderAccountAdded() {
  return (
    <Box className="bg-white max-w-5xl mx-auto h-full grid place-items-center mb-5 p-14">
      <Box className="max-w-lg p-4">
        <Box className="flex flex-row items-center justify-center mb-8">
          <Avatar size={64} />
          <Divider
            className="md:w-56 w-40"
            labelPosition="center"
            styles={() => ({
              label: {
                "&:after": {
                  margin: "0 0 0 4px",
                  borderTopStyle: "dashed",
                },
                "&:before": {
                  margin: "0 4px 0 0",
                  borderTopStyle: "dashed",
                },
              },
            })}
            label={
              <Box className="bg-success-90 rounded-full w-5 h-5 flex flex-row items-center justify-center">
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 3.5L3.91353 6L9.25 1"
                    stroke="#09A034"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
            }
          />
          <Box className="rounded-full flex items-center justify-center">
            <Box
              component="img"
              src="https://cdn.axazara.com/brand/crilix/icon_rounded.svg"
              className="w-16 h-16"
            />
          </Box>
        </Box>
        <Box className="flex flex-col items-center justify-center">
          <Text className="text-heading-06-semibold mb-4">
            Provider added to Crilix
          </Text>
          <Text className="text-center mb-8">
            kjdlalh Providers added description
          </Text>
          <Button
            component={Link}
            to="/provider-accounts/add"
            rightIcon={<ArrowRightOutline className="w-6 h-6" />}
            className=""
          >
            Go to providers list
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
