import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  InputField,
  LoadingOverlay,
  Table,
  Text,
} from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  AddCircleFill,
  SearchStatusOutline,
  ProfileCircleOutline,
} from "@axazara/raiton-icons";
import { openModal } from "@axazara/raiton-molecules";
import { useDebouncedValue } from "@axazara/raiton-utils";
import React from "react";
import { EmptyState } from "@/components";
import { formatDate, PaginatedProviderAccounts } from "utilities";
import { Link } from "react-router-dom";
import { PaginationControls } from "@/components/PaginationControls";
import { EditProvidersInfoModals } from "../../components";
import { useGetInstanceProviders } from "../../services";
import { useSetPageMeta } from "@/hooks";

export function ProviderAccounts() {
  useSetPageMeta({
    title: "Provider Accounts",
    icon: ProfileCircleOutline,
  });

  const { data: providersData, isLoading } = useGetInstanceProviders({
    page: 1,
  });

  if (isLoading) return <LoadingOverlay visible />;

  if (providersData?.data && providersData.data.length > 0)
    return <ProviderAccountsPageRender initialData={providersData} />;

  return (
    <EmptyState
      title=""
      description=""
      primaryAction={
        <Button
          component={Link}
          to="/provider-account/add-provider-account"
          size="xs"
          rightIcon={<AddCircleFill className="w-6 h-6" />}
        >
          Add provider
        </Button>
      }
    />
  );
}
export function ProviderAccountsPageRender({
  initialData,
}: {
  initialData: PaginatedProviderAccounts;
}) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);

  const { data: providersInstance, isLoading } = useGetInstanceProviders({
    initialData,
    page,
    search: debouncedSearch,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="pt-4">
        <Box className="flex flex-row gap-4 sticky top-0 z-10 bg-white py-5">
          <InputField
            classNames={{ input: "rounded-xl" }}
            radius={12}
            placeholder="Search"
            size="xs"
            icon={<SearchNormal1Outline className="w-5 h-5" />}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full"
            value={searchQuery}
            rightSection={
              <Box>
                {debouncedSearch ? (
                  <CloseCircleFill
                    className="w-4 h-4 text-neutral-40"
                    onClick={() => {
                      setSearchQuery("");
                    }}
                  />
                ) : null}
              </Box>
            }
          />
          <Button
            component={Link}
            to="/provider-account/add-provider-account"
            size="xs"
            rightIcon={<AddCircleFill className="w-6 h-6" />}
          >
            Add provider
          </Button>
        </Box>
        <Box>
          <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
            <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
              <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
                <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
                  Account name
                </Table.HeadCell>
                <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
                  Provider
                </Table.HeadCell>
                <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
                  Added at
                </Table.HeadCell>
                <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
                  View
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y divide-neutral-80">
                {providersInstance?.data &&
                  providersInstance.data?.map((provider_account) => (
                    <Table.Row key={provider_account.id}>
                      <Table.Cell className="text-p-01 text-neutral-40">
                        {provider_account?.name}
                      </Table.Cell>
                      <Table.Cell>
                        <Box className="flex items-center gap-2">
                          <Avatar
                            size={32}
                            src={provider_account?.provider?.icon_url}
                          />
                          <Text className="text-p-01 text-neutral-40">
                            {provider_account?.provider?.name}
                          </Text>
                        </Box>
                      </Table.Cell>
                      <Table.Cell>
                        <Text className="text-p-01 text-neutral-40">
                          {provider_account.created_at &&
                            formatDate(
                              provider_account.created_at,
                              "en",
                              "D MMM YYYY, HH:MM",
                            )}
                        </Text>
                      </Table.Cell>
                      <Table.Cell className="flex justify-end">
                        <Box className="flex space-x-2">
                          <ActionIcon
                            onClick={() =>
                              openModal({
                                title: (
                                  <Text className="text-heading-05-bold text-neutral-30">
                                    Update provider informations
                                  </Text>
                                ),
                                size: "lg",
                                centered: true,
                                children: (
                                  <EditProvidersInfoModals
                                    provider={provider_account}
                                  />
                                ),
                                closeOnClickOutside: true,
                              })
                            }
                            color="primary"
                            className="p-0.5"
                            size="xl"
                            radius={100}
                          >
                            <SearchStatusOutline className="w-6 h-6 text-primary-50" />
                          </ActionIcon>
                        </Box>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Box>
          <Box>
            {providersInstance?.data && (
              <PaginationControls
                page={page}
                pagination={providersInstance?.pagination}
                onPageChange={setPage}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
