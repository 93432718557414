import { Navigate, Route, Routes } from "react-router-dom";
import { AuthLayout, PrivateOutlet, RootLayout } from "@/layouts";
import { appPaths } from "@/configs";
import {
  AddProviderAccounts,
  AddProviderForm,
  Bundle,
  DiscountCodes,
  Invitation,
  Login,
  NotFound,
  OrderDetails,
  Orders,
  Pricing,
  ProviderAccountAdded,
  ProviderAccounts,
  RefillDetails,
  Refills,
  Services,
  StaffDetailsPage,
  Staffs,
  Transactions,
  UserDetails,
  Users,
  Countries,
} from "@/features";

export function AppRoutes() {
  return (
    <Routes>
      <Route path={appPaths.root} element={<PrivateOutlet />}>
        <Route path={appPaths.root} element={<RootLayout />}>
          <Route
            path={appPaths.root}
            element={<Navigate to={appPaths.users.path} />}
          />

          <Route path={appPaths.users.name}>
            <Route index element={<Users />} />
            <Route path=":id" element={<UserDetails />} />
          </Route>

          <Route path={appPaths.staffs.name}>
            <Route index element={<Staffs />} />
            <Route path=":id" element={<StaffDetailsPage />} />
          </Route>

          <Route path={appPaths.orders.name}>
            <Route index element={<Orders />} />
            <Route path=":id" element={<OrderDetails />} />
          </Route>

          <Route path={appPaths.providerAccount.name}>
            <Route index element={<ProviderAccounts />} />
            <Route
              path={appPaths.addProvider.name}
              element={<AddProviderAccounts />}
            />
            <Route
              path={appPaths.addProviderForm.path}
              element={<AddProviderForm />}
            />
            <Route
              path={appPaths.providerSucceed.name}
              element={<ProviderAccountAdded />}
            />
          </Route>

          <Route path={appPaths.transactions.name} element={<Transactions />} />
          <Route path={appPaths.services.name} element={<Services />} />
          <Route path={appPaths.countries.name} element={<Countries />} />

          <Route path={appPaths.pricing.name} element={<Pricing />} />
          <Route path={appPaths.bundle.name} element={<Bundle />} />
          <Route
            path={appPaths.discountCodes.name}
            element={<DiscountCodes />}
          />
          <Route path={appPaths.refills.name}>
            <Route index element={<Refills />} />
            <Route path=":id" element={<RefillDetails />} />
          </Route>
        </Route>
      </Route>
      <Route path={appPaths.auth} element={<AuthLayout />}>
        <Route path={appPaths.login.name} element={<Login />} index />
      </Route>
      <Route path={appPaths.invitation.path} element={<Invitation />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
