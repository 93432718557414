import { Refills } from "utilities";
import { RefillsTableRow } from "./RefillsTableRow";
import { Box, Table } from "@axazara/raiton-atoms";

export function RefillsTable({
  refills,
  withUser = false,
}: {
  refills: Refills;
  withUser?: boolean;
}) {
  return (
    <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            ID
          </Table.HeadCell>
          {withUser && (
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
              User
            </Table.HeadCell>
          )}
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Amount
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Coins
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Status
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Date
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
            View
          </Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {refills?.map((refill) => {
            return (
              <RefillsTableRow
                withUser={withUser}
                refill={refill}
                key={refill.id}
              />
            );
          })}
        </Table.Body>
      </Table>
    </Box>
  );
}
