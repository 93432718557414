import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "@/css/tailwind.css";
import "@/css/styles.css";

import { App } from "./App";

ReactDOM.createRoot(
  document.getElementById("axazara-crilix-staffs") as HTMLElement,
).render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
);
