import { Tag, Text } from "@axazara/raiton-atoms";
import { RefillStatus } from "utilities";

export function RefillStatusBadge({ status }: { status: RefillStatus }) {
  return (
    <>
      {
        {
          [RefillStatus.INITIATED]: (
            <Tag
              className="text-p-01-semibold normal-case bg-primary border border-primary-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-primary-30 text-p-01-semibold"
              >
                Initiated
              </Text>
            </Tag>
          ),
          [RefillStatus.PENDING_PAYMENT]: (
            <Tag
              className="text-p-01-semibold normal-case bg-secondary border border-secondary-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-secondary-30 text-p-01-semibold"
              >
                Pending
              </Text>
            </Tag>
          ),
          [RefillStatus.PAYMENT_FAILED]: (
            <Tag
              className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-critical-30 text-p-01-semibold"
              >
                Payment failed
              </Text>
            </Tag>
          ),
          [RefillStatus.FAILED]: (
            <Tag
              className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-critical-30 text-p-01-semibold"
              >
                Failed
              </Text>
            </Tag>
          ),
          [RefillStatus.SUCCESS]: (
            <Tag
              className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-success-30 text-p-01-semibold"
              >
                Success
              </Text>
            </Tag>
          ),
        }[status]
      }
    </>
  );
}
