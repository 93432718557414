import { Box, Text, Divider, Tooltip } from "@axazara/raiton-atoms";

export function TransactionsMetadata({
  metadata,
}: {
  metadata: Record<string, string>;
}) {
  if (Object.keys(metadata).every((key) => metadata[key] === null)) return null;

  return (
    <Box className="mb-6">
      <Text className="text-heading-06-semibold mb-4">Metadata</Text>
      <Divider className="border-neutral-80 mb-6" />
      <Box className="space-y-4">
        {Object.keys(metadata).map((key) => (
          <Box
            key={key}
            className="flex flex-row items-center gap-4 justify-between"
          >
            <Text className="text-neutral-50 text-p-01-medium">{key}</Text>
            <Tooltip label={metadata[key]}>
              <Text className="text-neutral-30 text-p-01 whitespace-nowrap text-ellipsis overflow-hidden w-60">
                {metadata[key]}
              </Text>
            </Tooltip>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
