import {
  Avatar,
  Box,
  Button,
  Divider,
  InputField,
  OTPInput,
  Text,
} from "@axazara/raiton-atoms";
import { SearchNormal1Outline } from "@axazara/raiton-icons";
import { formatDate, ProviderAccount } from "utilities";
import { closeModal, useForm } from "@axazara/raiton-molecules";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { ProviderFormBuilder } from ".";
import { useUpdateProviderInformation } from "../services";
import { Link } from "react-router-dom";

export function EditProvidersInfoModals({
  provider,
}: {
  provider: ProviderAccount;
}) {
  const useUpdateProviderInformationMutation = useUpdateProviderInformation(
    provider?.id,
  );

  const providerEditForm = useForm({
    initialValues: provider?.provider?.fields?.reduce(
      (obj: any, item) => {
        obj[item.name] = (provider as any)![item.name];
        return obj;
      },
      { account_name: provider.name },
    ),
  });

  async function handleSubmit(values: any) {
    try {
      const response =
        await useUpdateProviderInformationMutation.mutateAsync(values);

      showSuccessNotification((response as any)?.message);
      closeModal("");
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  return (
    <Box className="space-y-4">
      <Text className="text-heading-06-semibold mt-12">
        Provider Information
      </Text>
      <Divider color="neutral.9" className="border-neutral-90" />
      <Box className="flex justify-between">
        <Text className="text-p-01-semibold">Account name</Text>
        <Text className="text-p-01">{provider.name}</Text>
      </Box>
      <Box className="flex justify-between">
        <Text className="text-p-01-semibold">Added at</Text>
        <Text className="text-p-01">
          {formatDate(provider.created_at, "en", "D MMM YYYY")}
        </Text>
      </Box>
      <Text className="text-p-01-semibold">Added by</Text>
      <Box className="flex justify-between">
        <Box className="flex items-center gap-4">
          <Avatar src={provider.added_by?.profile_photo_url} size={48} />
          <Box>
            <Text className="text-p-01-medium">
              {provider.added_by.first_name} {provider.added_by.last_name}
            </Text>
            <Text className="text-caption-medium text-neutral-60">
              {provider.added_by.email}
            </Text>
          </Box>
        </Box>
        <Button
          component={Link}
          to={`/staffs/${provider.added_by.id}`}
          size="xs"
          variant="outline"
          rightIcon={<SearchNormal1Outline className="w-6 h-6" />}
        >
          View
        </Button>
      </Box>
      <Box
        component="form"
        onSubmit={providerEditForm.onSubmit((values) => handleSubmit(values))}
        className="space-y-6"
      >
        <InputField
          label="Account name"
          placeholder="Provider Account name"
          size="xs"
          {...providerEditForm.getInputProps("account_name")}
        />
        {provider && (
          <ProviderFormBuilder
            form={providerEditForm}
            fields={provider?.provider?.fields}
          />
        )}
        <Divider color="neutral.9" className="border-neutral-90" />
        <Box className="flex flex-col items-center justify-center">
          <Text className="text-p-01 text-neutral-30 text-center mb-4">
            Enter the OTP code sent to your phone number
          </Text>
          <OTPInput
            radius={12}
            {...providerEditForm.getInputProps("otp_code")}
            size="lg"
            allowedCharacters="numeric"
            className="flex justify-center"
            onChange={(res) => {
              providerEditForm.setFieldValue("otp_code", res);
            }}
          />
        </Box>
        <Box className="flex justify-end mb-4">
          <Button
            disabled={!providerEditForm.isDirty()}
            loading={useUpdateProviderInformationMutation.isPending}
            type="submit"
          >
            Update
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
