import { Table, Tag, Box, ActionIcon, Text } from "@axazara/raiton-atoms";
import { SearchStatusOutline } from "@axazara/raiton-icons";
import { openModal } from "@axazara/raiton-molecules";
import { Transaction, formatDate } from "utilities";
import { TransactionsDetailsModal } from "./TransactionsDetailsModal";

export function TransactionsTableRow({
  transaction,
}: {
  transaction: Transaction;
}) {
  return (
    <Table.Row key={transaction.id}>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">{transaction.id}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">
          {formatDate(transaction.created_at, "en", "D MMM YYYY, HH:mm")}
        </Text>
      </Table.Cell>
      <Table.Cell className="text-p-01 text-neutral-40">
        <Text className="text-p-01 text-neutral-40">{transaction.object}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text className=" text-p-01-medium text-success-40">
          {transaction.amount} Coins
        </Text>
      </Table.Cell>
      <Table.Cell>
        {transaction.type == "credit" && (
          <Tag
            className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
            size="lg"
          >
            <Text
              component="span"
              className="text-success-40 text-p-01-semibold"
            >
              Crédit
            </Text>
          </Tag>
        )}

        {transaction.type == "debit" && (
          <Tag
            className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3"
            size="lg"
          >
            <Text
              component="span"
              className="text-critical-40 text-p-01-semibold"
            >
              Débit
            </Text>
          </Tag>
        )}
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <Box
          onClick={() =>
            openModal({
              children: <TransactionsDetailsModal transaction={transaction} />,
              title: (
                <Text className="text-heading-06-bold text-primary-40">
                  Details de la transaction
                </Text>
              ),
              size: "lg",
              closeOnClickOutside: true,
              centered: true,
            })
          }
          className="flex justify-end space-x-2"
        >
          <ActionIcon color="primary" size="xl" radius={100} className="p-0.5">
            <SearchStatusOutline className="w-6 h-6 text-primary-50" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}
