import React from "react";
import {
  Avatar,
  Box,
  InputField,
  LoadingOverlay,
  Table,
  Text,
} from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  Simcard2Outline,
} from "@axazara/raiton-icons";
import { useDebouncedValue } from "@axazara/raiton-utils";
import { EmptyState } from "@/components";
import { useGetServices } from "../../services";
import { useSetPageMeta } from "@/hooks";

export function Services() {
  const { data: services, isLoading } = useGetServices({});

  if (isLoading) return <LoadingOverlay visible />;

  if (services && services?.length > 0) return <ServicesPageRenderer />;

  return <EmptyState title="No order yet" />;
}

function ServicesPageRenderer() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const { data: services } = useGetServices({ search: debouncedSearch });

  useSetPageMeta({
    title: "Services",
    icon: Simcard2Outline,
  });

  return (
    <Box>
      <Box className="pt-4">
        <Box>
          <Box className="flex justify-end w-full sticky top-0 z-10 bg-white py-5">
            <InputField
              placeholder="Search service"
              size="xs"
              icon={<SearchNormal1Outline className="w-5 h-5" />}
              className="w-full"
              classNames={{
                input: "rounded-xl",
              }}
              onChange={(e) => setSearchQuery(e.target.value)}
              rightSection={
                debouncedSearch ? (
                  <CloseCircleFill
                    className="w-4 h-4 text-gray-600"
                    onClick={() => {
                      setSearchQuery("");
                    }}
                  />
                ) : null
              }
            />
          </Box>
          <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
            <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
              <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
                <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
                  Service
                </Table.HeadCell>

                <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
                  Code service
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y divide-neutral-80">
                {services &&
                  services?.map((service) => {
                    return (
                      <Table.Row key={service.short_code}>
                        <Table.Cell>
                          <Box className="flex items-center space-x-4">
                            <Avatar src={service.icon_url} size={32} />
                            <Text className="text-p-01-medium text-neutral-40">
                              {service.name}
                            </Text>
                          </Box>
                        </Table.Cell>
                        <Table.Cell className="flex justify-end">
                          <Text className="text-p-01-medium text-neutral-40">
                            {service.short_code}
                          </Text>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
