import { Box, Tooltip, Text } from "@axazara/raiton-atoms";
import { useClipboard } from "@axazara/raiton-utils";
import { CopySuccessOutline, CopyOutline } from "@axazara/raiton-icons";

export function CopyableInformationItem({
  label,
  value,
}: {
  label: string;
  value: string | undefined;
}) {
  const { copy, copied } = useClipboard({ timeout: 500 });

  return (
    <Box className="flex flex-row justify-between mb-4">
      <Text className="text-p-01 text-neutral-40">{label}</Text>
      <Box className="flex items-center  justify-end">
        <Text className="text-p-01 text-neutral-40">{value}</Text>
        <Tooltip label={<Text>{copied ? "copied" : "copy"}</Text>}>
          <Box onClick={() => copy(value)} className="cursor-pointer">
            {copied ? (
              <CopySuccessOutline className="w-4 h-4 text-primary-40 ml-2" />
            ) : (
              <CopyOutline className="w-4 h-4 text-primary-40 ml-2" />
            )}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
}

export function InformationRow({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) {
  return (
    <Box className="flex flex-row justify-between mb-4">
      <Text className="text-p-01 text-neutral-40">{label}</Text>
      <Box className="flex items-center  justify-end">
        <Text className="text-p-01 text-neutral-40">{value}</Text>
      </Box>
    </Box>
  );
}
