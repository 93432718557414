import React from "react";
import {
  Menu,
  UnstyledButton,
  Avatar,
  Text,
  InputField,
  Group,
} from "@axazara/raiton-atoms";
import {
  ArrowDownOutline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { classNames } from "@axazara/raiton-utils";
import { Service, useFilterDropdownStyles } from "utilities";

type ServiceFilterMenuProps = {
  services: Service[] | undefined;
  selectedService: Service | null;
  setSelectedService: React.Dispatch<React.SetStateAction<Service | null>>;
  searchServiceQuery: string;
  setSearchServiceQuery: (query: string) => void;
  openedServiceFilter: boolean;
  setOpenedServiceFilter: (open: boolean) => void;
};

export const ServiceFilterMenu = ({
  services,
  selectedService,
  setSelectedService,
  searchServiceQuery,
  setSearchServiceQuery,
  openedServiceFilter,
  setOpenedServiceFilter,
}: ServiceFilterMenuProps) => {
  const { classes } = useFilterDropdownStyles({ opened: openedServiceFilter });

  return (
    <Menu
      onOpen={() => setOpenedServiceFilter(true)}
      onClose={() => setOpenedServiceFilter(false)}
      radius="md"
      withinPortal
      width="100%"
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          {openedServiceFilter ? (
            <InputField
              onClick={(e) => e.stopPropagation()}
              classNames={{
                input:
                  "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none min-h-[28px] h-7",
              }}
              value={searchServiceQuery}
              className="w-full"
              iconWidth={12}
              size="xs"
              onChange={(e) => setSearchServiceQuery(e.target.value)}
              icon={<SearchNormal1Outline className="w-3 h-3" />}
            />
          ) : selectedService ? (
            <>
              <Group spacing="xs" className="w-full">
                {selectedService?.icon_url && (
                  <Avatar size={24} src={selectedService?.icon_url} />
                )}
                <Text
                  className={`${classes.label} truncate whitespace-nowrap w-16`}
                >
                  {selectedService?.name}
                </Text>
              </Group>
              {selectedService && (
                <CloseCircleFill
                  className="w-4 h-4 text-neutral-50 mr-2"
                  onClick={() => {
                    setSelectedService(null);
                  }}
                />
              )}
            </>
          ) : (
            <Text className="text-footer text-neutral-40">
              Filter by service
            </Text>
          )}
          <ArrowDownOutline
            className={classNames(classes.icon, "w-4 h-4 text-neutral-40")}
          />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown className="!w-max max-h-80 overflow-y-auto">
        {services?.map((service) => (
          <Menu.Item
            key={service.short_code}
            onClick={() => setSelectedService(service)}
            className={
              selectedService?.name === service.name ? "bg-neutral-90" : ""
            }
            icon={<Avatar size={24} src={service.icon_url} />}
          >
            {service.name}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
