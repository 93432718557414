/* eslint-disable no-param-reassign */
import { createAxiosInstance } from "@axazara/shuriken";
import { PersistentStorage } from "utilities";
import { appConfig, appPaths } from "@/configs";
import { StorageKeys } from "@/utils";
import { i18n } from "@/context";

function getAccessToken() {
  const accessToken = PersistentStorage.getData(
    StorageKeys.CRILIX_TOKEN_KEY,
    false,
  );
  return accessToken;
}

function isAuthPage(pathname: string) {
  return [appPaths.login.path].includes(pathname);
}

export const axios = createAxiosInstance({
  baseURL: appConfig.CRILIX_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },

  onRequest(config) {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers!.Authorization = `Bearer ${accessToken}`;
    }

    config.headers!["x-session-locale"] = i18n.language;

    return config;
  },

  onResponse(response) {
    return response.data;
  },

  onError(error) {
    // access token expired
    if (
      error.response?.status === 401 &&
      !isAuthPage(window.location.pathname)
    ) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.pathname = appPaths.login.path;
      return Promise.reject(error.response);
    }

    if (
      error.response?.status === 401 &&
      isAuthPage(window.location.pathname)
    ) {
      window.localStorage.clear();
      window.sessionStorage.clear();
    }

    return Promise.reject(error.response?.data);
  },
});
