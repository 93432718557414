import React from "react";
import {
  Box,
  Button,
  InputField,
  Text,
  LoadingOverlay,
  Menu,
  UnstyledButton,
  Group,
} from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  AddCircleFill,
  ArrowDownOutline,
  TicketDiscountOutline,
} from "@axazara/raiton-icons";
import { openModal } from "@axazara/raiton-molecules";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";
import { EmptyState } from "@/components";
import { PaginatedDiscounts, useFilterDropdownStyles } from "utilities";
import { PaginationControls } from "@/components/PaginationControls";
import { useGetDiscountsCode } from "../../services";
import { AddDiscountCodeModal, DiscountCodeTable } from "../../components";
import { useSetPageMeta } from "@/hooks";

export function DiscountCodes() {
  useSetPageMeta({
    title: "Discount codes",
    icon: TicketDiscountOutline,
  });

  const { data: discountsCodeData, isLoading } = useGetDiscountsCode({
    page: 1,
  });

  if (isLoading)
    return (
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ color: "secondary" }}
      />
    );

  if (discountsCodeData && discountsCodeData?.data?.length > 0)
    return <DiscountCodeRender discountsCodeData={discountsCodeData} />;

  return (
    <Box className="h-full w-full grid place-items-center">
      <EmptyState
        title={"empty_state_title"}
        primaryAction={
          <Button
            onClick={() =>
              openModal({
                title: (
                  <Text className="text-heading-06-bold text-neutral-30">
                    Add discount code
                  </Text>
                ),
                size: "lg",
                centered: true,
                children: <AddDiscountCodeModal />,
                closeOnClickOutside: false,
              })
            }
            size="xs"
            rightIcon={<AddCircleFill className="w-6 h-6" />}
          >
            Add discount code
          </Button>
        }
      />
    </Box>
  );
}
function DiscountCodeRender({
  discountsCodeData,
}: {
  discountsCodeData: PaginatedDiscounts;
}) {
  const statusData = [
    { label: "All", value: "all", color: "bg-neutral-50" },
    { label: "Active", value: "active", color: "bg-success-50" },
    { label: "Inactive", value: "inactive", color: "bg-critical-50" },
  ];

  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState<any>(statusData[0]);

  const [openedStatusFilter, setOpenedStatusFilter] = React.useState(false);
  const { classes: statusFilterClasses } = useFilterDropdownStyles({
    opened: openedStatusFilter,
    width: "20%",
  });

  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const statusItems = statusData.map((item) => (
    <Menu.Item
      icon={<Box className={`w-3 h-3 rounded-full ${item.color}`} />}
      onClick={() => setStatus(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  const { data: discountCode, refetch } = useGetDiscountsCode({
    page,
    initialData: discountsCodeData,
    status: status.value,
  });

  return (
    <Box>
      <Box className="pt-4">
        <Box className="flex flex-row gap-4 sticky top-0 z-10 bg-white py-5">
          <InputField
            classNames={{ input: "rounded-xl" }}
            radius={12}
            placeholder="Search discount code"
            size="xs"
            icon={<SearchNormal1Outline className="w-5 h-5" />}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full"
            value={searchQuery}
            rightSection={
              <Box>
                {debouncedSearch ? (
                  <CloseCircleFill
                    className="w-4 h-4 text-neutral-40"
                    onClick={() => {
                      setSearchQuery("");
                    }}
                  />
                ) : null}
              </Box>
            }
          />
          <Menu
            onOpen={() => setOpenedStatusFilter(true)}
            onClose={() => setOpenedStatusFilter(false)}
            radius="md"
            width="target"
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton className={statusFilterClasses.control}>
                <Group spacing="xs">
                  <Box className={`w-3 h-3 rounded-full ${status?.color}`} />
                  <span className={statusFilterClasses.label}>
                    {status?.label}
                  </span>
                </Group>
                <ArrowDownOutline
                  className={classNames(
                    statusFilterClasses.icon,
                    "w-4 h-4 text-neutral-40",
                  )}
                />
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>{statusItems}</Menu.Dropdown>
          </Menu>
          <Button
            onClick={() =>
              openModal({
                title: (
                  <Text className="text-heading-06-bold text-neutral-30">
                    Add discount code
                  </Text>
                ),
                size: "lg",
                centered: true,
                children: <AddDiscountCodeModal />,
                closeOnClickOutside: false,
              })
            }
            size="xs"
            rightIcon={<AddCircleFill className="w-6 h-6" />}
          >
            Add discount code
          </Button>
        </Box>
        <Box>
          {discountCode?.data && (
            <DiscountCodeTable
              discountCode={discountCode.data}
              refetch={refetch}
            />
          )}
          <Box>
            {discountCode?.data && (
              <PaginationControls
                page={page}
                pagination={discountCode?.pagination}
                onPageChange={setPage}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
