import React from "react";
import {
  Menu,
  UnstyledButton,
  Avatar,
  Text,
  InputField,
  Group,
  Box,
} from "@axazara/raiton-atoms";
import {
  ArrowDownOutline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { classNames } from "@axazara/raiton-utils";
import {
  PaginatedProviderAccounts,
  ProviderAccount,
  useFilterDropdownStyles,
} from "utilities";

type ProviderFilterMenuProps = {
  providers: PaginatedProviderAccounts | undefined;
  selectedProvider: ProviderAccount | null;
  setSelectedProvider: React.Dispatch<
    React.SetStateAction<ProviderAccount | null>
  >;
  searchProviderQuery: string;
  setSearchProviderQuery: React.Dispatch<React.SetStateAction<string>>;
  openedProviderFilter: boolean;
  setOpenedProviderFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProviderFilterMenu = ({
  providers,
  selectedProvider,
  setSelectedProvider,
  searchProviderQuery,
  setSearchProviderQuery,
  openedProviderFilter,
  setOpenedProviderFilter,
}: ProviderFilterMenuProps) => {
  const { classes } = useFilterDropdownStyles({ opened: openedProviderFilter });

  return (
    <Menu
      onOpen={() => setOpenedProviderFilter(true)}
      onClose={() => setOpenedProviderFilter(false)}
      radius="md"
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          {openedProviderFilter ? (
            <InputField
              onClick={(e) => e.stopPropagation()}
              classNames={{
                input:
                  "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none min-h-[28px] h-7",
              }}
              value={searchProviderQuery}
              className="w-full"
              iconWidth={12}
              size="xs"
              onChange={(e) => setSearchProviderQuery(e.target.value)}
              icon={<SearchNormal1Outline className="w-3 h-3" />}
            />
          ) : selectedProvider ? (
            <>
              <Group spacing="xs">
                {selectedProvider?.provider?.icon_url && (
                  <Avatar
                    size={24}
                    src={selectedProvider?.provider?.icon_url}
                  />
                )}
                <Text
                  className={`${classes.label} truncate whitespace-nowrap w-16`}
                >
                  {selectedProvider?.provider?.name}
                </Text>
              </Group>
              {selectedProvider && (
                <CloseCircleFill
                  className="w-4 h-4 text-neutral-60"
                  onClick={() => setSelectedProvider(null)}
                />
              )}
            </>
          ) : (
            <Text className="text-footer text-neutral-40">
              Filter by provider
            </Text>
          )}
          <ArrowDownOutline
            className={classNames(classes.icon, "w-4 h-4 text-neutral-40")}
          />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown className="!w-max max-h-80 overflow-y-auto">
        {providers?.data?.map((provider) => (
          <Menu.Item
            key={provider?.provider?.id}
            onClick={() => setSelectedProvider(provider)}
            icon={<Avatar size={24} src={provider?.provider?.icon_url} />}
            className={
              selectedProvider?.provider?.name === provider?.provider?.name
                ? "bg-neutral-90"
                : ""
            }
          >
            <Box className="flex flex-col">
              <Text className="text-caption-medium">
                {provider?.provider?.name}
              </Text>
              <Text className="text-footer text-ellipsis overflow-hidden whitespace-nowrap w-16">
                {provider?.name}
              </Text>
            </Box>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
