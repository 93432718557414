import { getEnvConfigFactory } from "@axazara/raiton-utils/lib/get-env-config-factory";

const currentEnv: string = process.env.VITE_AXAZARA_ENV || "dev";

const getEnvConfig = getEnvConfigFactory(currentEnv);

export const appConfig = {
  CRILIX_API_BASE_URL: getEnvConfig({
    preset: "https://crilix-api.axaship.com",
    prod: "https://api.crilix.com",
  }),

  VITE_AXAZARA_ENV: getEnvConfig({
    dev: "dev",
    beta: "beta",
    alpha: "alpha",
    prod: "prod",
  }),
};
