import {
  Box,
  DatePickerInput,
  Group,
  InputField,
  LoadingOverlay,
  Menu,
  UnstyledButton,
} from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  Calendar2Outline,
  ArrowDownOutline,
  RefreshOutline,
} from "@axazara/raiton-icons";
import { PaginatedRefills, useFilterDropdownStyles } from "utilities";
import { EmptyState, RefillsTable } from "@/components";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";
import React from "react";
import { DateRange } from "@/types";
import { useGetRefills } from "../../services";
import { PaginationControls } from "@/components/PaginationControls";
import { useSetPageMeta } from "@/hooks";

export function Refills() {
  useSetPageMeta({
    title: "Refills",
    icon: RefreshOutline,
  });

  const { data: refillsData, isLoading } = useGetRefills({ page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (refillsData?.data && refillsData?.data.length > 0)
    return <RefillsPageRenderer refillsData={refillsData} />;

  return <EmptyState title="No refills yet" />;
}

function RefillsPageRenderer({
  refillsData,
}: {
  refillsData: PaginatedRefills;
}) {
  const statusData = [
    { label: `${"All"}`, value: "all", color: "bg-neutral-50" },
    { label: `${"Initiated"}`, value: "initiated", color: "bg-primary-50" },
    { label: `${"Pending"}`, value: "pending", color: "bg-secondary-50" },
    { label: `${"Success"}`, value: "success", color: "bg-success-50" },
    { label: `${"Failed"}`, value: "failed", color: "bg-critical-50" },
    {
      label: `${"Payment Failed"}`,
      value: "payment_failed",
      color: "bg-critical-50",
    },
  ];

  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>([
    null,
    null,
  ]);

  const [openedStatusFilter, setOpenedStatusFilter] = React.useState(false);
  const { classes: StatusFilterClasses } = useFilterDropdownStyles({
    opened: openedStatusFilter,
    width: "20%",
  });

  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState<any>(statusData[0]);

  const { data: refills, isLoading } = useGetRefills({
    page,
    search: debouncedSearch,
    initialData: refillsData,
    status: status.value,
    dateRange: selectedDateRange,
  });

  const statusItems = statusData.map((item) => (
    <Menu.Item
      icon={<Box className={`w-3 h-3 rounded-full ${item.color}`} />}
      onClick={() => setStatus(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="pt-4">
        <Box className="flex flex-row gap-4 sticky top-0 z-10 bg-white py-5">
          <InputField
            classNames={{ input: "rounded-xl" }}
            radius={12}
            placeholder="Search refill"
            size="xs"
            icon={<SearchNormal1Outline className="w-5 h-5" />}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full"
            value={searchQuery}
            rightSection={
              <Box>
                {debouncedSearch ? (
                  <CloseCircleFill
                    className="w-4 h-4 text-neutral-40"
                    onClick={() => {
                      setSearchQuery("");
                    }}
                  />
                ) : null}
              </Box>
            }
          />

          <Menu
            onOpen={() => setOpenedStatusFilter(true)}
            onClose={() => setOpenedStatusFilter(false)}
            radius="md"
            width="max-content"
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton className={StatusFilterClasses.control}>
                <Group spacing="xs">
                  <Box className={`w-3 h-3 rounded-full ${status?.color}`} />
                  <span className={StatusFilterClasses.label}>
                    {status?.label}
                  </span>
                </Group>
                <ArrowDownOutline
                  className={classNames(
                    StatusFilterClasses.icon,
                    "w-4 h-4 text-neutral-40",
                  )}
                />
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>{statusItems}</Menu.Dropdown>
          </Menu>

          <DatePickerInput
            size="xs"
            type="range"
            clearable
            icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
            // @ts-ignore
            placeholder="Filter by date"
            value={selectedDateRange}
            numberOfColumns={2}
            styles={(theme) => ({
              root: {
                "> div": {
                  borderColor: theme.colors.neutral[8],
                },
              },
            })}
            onChange={setSelectedDateRange}
            allowSingleDateSelectionInRange
            rightSection={
              selectedDateRange[1] ? null : (
                <ArrowDownOutline className="w-4 h-4 text-neutral-40" />
              )
            }
          />
        </Box>

        <Box>
          {refills?.data && (
            <>
              <RefillsTable refills={refills?.data} withUser />
              <PaginationControls
                page={page}
                pagination={refills?.pagination}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
