import { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Table,
  Text,
  ActionIcon,
  LoadingOverlay,
  Pagination,
  DatePickerInput,
} from "@axazara/raiton-atoms";
import { openModal } from "@axazara/raiton-molecules";
import {
  SearchStatusOutline,
  ArrowDownOutline,
  Calendar2Outline,
} from "@axazara/raiton-icons";
import { formatDate } from "utilities";
import { DatesRangeValue } from "@axazara/raiton-atoms/lib/date-utils";
import { ActivityDetailsModal } from "./ActivityDetailsModal";
import { useGetStaffActivities } from "../services";
import { EmptyState } from "@/components";
import { Activities } from "@/models";
import { DateRange } from "@/types";

function DatePickerSection({
  selectedDateRange,
  setSelectedDateRange,
}: {
  selectedDateRange: DateRange;
  setSelectedDateRange: (value: DatesRangeValue) => void;
}) {
  return (
    <Box className="flex justify-end mb-8 space-x-4 px-6">
      <DatePickerInput
        size="xs"
        type="range"
        clearable
        icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
        value={selectedDateRange}
        numberOfColumns={2}
        styles={(theme) => ({
          root: {
            "> div": {
              borderColor: theme.colors.neutral[8],
            },
          },
        })}
        onChange={setSelectedDateRange}
        allowSingleDateSelectionInRange
        rightSection={
          selectedDateRange[1] ? null : (
            <ArrowDownOutline className="w-4 h-4 text-neutral-40" />
          )
        }
      />
    </Box>
  );
}

function ActivitiesTable({ activities }: { activities: Activities }) {
  return (
    <Box className="px-6">
      <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
        <Table className="rounded-xl overflow-hidden border-collapse border border-neutral-80 border-spacing-0">
          <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
              ID
            </Table.HeadCell>
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
              Date
            </Table.HeadCell>
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
              Status
            </Table.HeadCell>
            <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
              View
            </Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {activities.map((activity) => (
              <Table.Row className="border-b border-neutral-90">
                <Table.Cell className="w-60">
                  <Text component="span" className="text-p-01-semibold">
                    {activity.id}
                  </Text>
                </Table.Cell>
                <Table.Cell className="w-60">
                  <Text component="span" className="text-p-01-semibold">
                    {formatDate(activity.updated_at, "en")}
                  </Text>
                </Table.Cell>
                <Table.Cell>
                  <Text className="text-neutral-40 text-p-01-semibold">
                    {activity.action}
                  </Text>
                </Table.Cell>
                <Table.Cell className="flex justify-end">
                  <ActionIcon
                    onClick={() =>
                      openModal({
                        size: "lg",
                        children: <ActivityDetailsModal activity={activity} />,
                      })
                    }
                    color="primary"
                    className="p-0.5"
                    size="xl"
                    radius={100}
                  >
                    <SearchStatusOutline className="w-6 h-6 text-primary-50" />
                  </ActionIcon>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Box>
    </Box>
  );
}

function PaginationSection({
  page,
  setPage,
  total,
}: {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  total: number;
}) {
  return (
    <Box className="flex w-full justify-end items-center mb-16 px-6 pt-8">
      <Pagination
        size="xl"
        radius={0}
        spacing={0}
        color="green"
        initialPage={1}
        onChange={setPage}
        page={page}
        total={total}
        style={{ gap: 0 }}
        styles={() => ({
          item: {
            fontSize: 14,
            "&:focus": {
              outline: "none",
            },
          },
        })}
      />
    </Box>
  );
}

function StaffActivitiesRenderer({ id }: { id: string }) {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>([
    null,
    null,
  ]);
  const [page, setPage] = useState(1);

  const { data: activities, isLoading } = useGetStaffActivities(id, {
    page,
    dateRange: selectedDateRange,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <DatePickerSection
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
      />
      {activities && <ActivitiesTable activities={activities?.data} />}
      <PaginationSection
        page={page}
        setPage={setPage}
        total={activities?.pagination.last_page ?? 0}
      />
    </Box>
  );
}

export function StaffActivities({ id }: { id: string }) {
  const { data: activities, isLoading } = useGetStaffActivities(id, {});

  if (isLoading)
    return (
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ color: "secondary" }}
      />
    );

  if (activities && activities?.data?.length > 0)
    return <StaffActivitiesRenderer id={id} />;

  return <EmptyState title="No activities yet" />;
}
