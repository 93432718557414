import React from "react";
import {
  Menu,
  UnstyledButton,
  Avatar,
  Text,
  InputField,
  Group,
} from "@axazara/raiton-atoms";
import {
  ArrowDownOutline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { classNames } from "@axazara/raiton-utils";
import { Country, useFilterDropdownStyles } from "utilities";

type CountryFilterMenuProps = {
  countries: Country[] | undefined;
  selectedCountry: Country | null;
  setSelectedCountry: React.Dispatch<React.SetStateAction<Country | null>>;
  searchCountryQuery: string;
  setSearchCountryQuery: React.Dispatch<React.SetStateAction<string>>;
  openedCountryFilter: boolean;
  setOpenedCountryFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CountryFilterMenu = ({
  countries,
  selectedCountry,
  setSelectedCountry,
  searchCountryQuery,
  setSearchCountryQuery,
  openedCountryFilter,
  setOpenedCountryFilter,
}: CountryFilterMenuProps) => {
  const { classes } = useFilterDropdownStyles({ opened: openedCountryFilter });

  return (
    <Menu
      onOpen={() => setOpenedCountryFilter(true)}
      onClose={() => setOpenedCountryFilter(false)}
      radius="md"
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          {openedCountryFilter ? (
            <InputField
              onClick={(e) => e.stopPropagation()}
              classNames={{
                input:
                  "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none min-h-[28px] h-7",
              }}
              value={searchCountryQuery}
              className="w-full"
              iconWidth={12}
              size="xs"
              onChange={(e) => setSearchCountryQuery(e.target.value)}
              icon={<SearchNormal1Outline className="w-3 h-3" />}
            />
          ) : selectedCountry ? (
            <>
              <Group spacing="xs">
                {selectedCountry?.flag && (
                  <Avatar size={24} src={selectedCountry?.flag} />
                )}
                <Text
                  className={`${classes.label} truncate whitespace-nowrap w-16`}
                >
                  {selectedCountry?.name}
                </Text>
              </Group>
              {selectedCountry && (
                <CloseCircleFill
                  className="w-4 h-4 text-neutral-60"
                  onClick={() => setSelectedCountry(null)}
                />
              )}
            </>
          ) : (
            <Text className="text-footer text-neutral-40">
              Filter by country
            </Text>
          )}
          <ArrowDownOutline
            className={classNames(classes.icon, "w-4 h-4 text-neutral-40")}
          />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown className="!w-max max-h-80 overflow-y-auto">
        {countries?.map((country) => (
          <Menu.Item
            key={country?.code}
            onClick={() => setSelectedCountry(country)}
            icon={<Avatar size={24} src={country?.flag} />}
            className={
              selectedCountry?.name === country.name ? "bg-neutral-90" : ""
            }
          >
            {country.name}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
