import { Menu, Box, Group, UnstyledButton } from "@axazara/raiton-atoms";
import { ArrowDownOutline } from "@axazara/raiton-icons";
import { classNames } from "@axazara/raiton-utils";
import React from "react";
import { useFilterDropdownStyles } from "utilities";

export interface StatusType {
  label: string;
  value: string;
  color: string;
}
interface StatusFilterMenuProps {
  statusData: Array<StatusType>;
  selectedStatus: StatusType | null;
  openedStatusFilter: boolean;
  setSelectedStatus: React.Dispatch<React.SetStateAction<StatusType | null>>;
  setOpenedStatusFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StatusFilterMenu = ({
  statusData,
  selectedStatus,
  setSelectedStatus,
  setOpenedStatusFilter,
  openedStatusFilter,
}: StatusFilterMenuProps) => {
  const { classes } = useFilterDropdownStyles({ opened: openedStatusFilter });

  const statusItems = statusData.map((item) => (
    <Menu.Item
      className={selectedStatus?.value === item.value ? "bg-neutral-90" : ""}
      icon={<Box className={`w-3 h-3 rounded-full ${item.color}`} />}
      onClick={() => setSelectedStatus(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpenedStatusFilter(true)}
      onClose={() => setOpenedStatusFilter(false)}
      radius="md"
      width="100%"
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          <Group spacing="xs">
            <Box className={`w-3 h-3 rounded-full ${selectedStatus?.color}`} />
            <span className={classes.label}>{selectedStatus?.label}</span>
          </Group>
          <ArrowDownOutline
            className={classNames(classes.icon, "w-4 h-4 text-neutral-40")}
          />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown className="!w-max">{statusItems}</Menu.Dropdown>
    </Menu>
  );
};
