import { ApiEndpoints } from "@/configs";
import { useGet } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { Countries } from "utilities";

export const useGetServices = ({ search }: { search?: string }) => {
  const params = buildFetchParams({
    search,
  });

  return useGet<Countries>(`${ApiEndpoints.countries}`, params);
};
