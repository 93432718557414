export const ApiEndpoints = {
  login: "/auth/staff/login",
  logout: "/staff/logout",
  profile: "/staff/profile",
  users: "/users",
  staffs: "/staffs/members",
  activities: "/staffs/activities",
  invitations: "/staffs/invites",
  orders: "/orders",
  services: "/utils/services",
  pricing: "/pricing",
  bundles: "/bundles",
  providersInstance: "/providers",
  domains: "/domains",
  discounts: "/discounts",
  faqs: "/faqs",
  top_up: "/top-up",
  transactions: "/transactions",
  wallet: "/wallet",
  providers: "/utils/providers",
  countries: "/utils/countries",
  utils_providers: "/utils/providers",
  refills: "/refills",
  currencies: "/utils/currencies",
  servicesList: "/utils/services",
  refundReasons: "/utils/refund-reasons",
};
