import { Box, LoadingOverlay } from "@axazara/raiton-atoms";

import { EmptyState, OrdersFilter, OrdersTable } from "@/components";
import { ShoppingCartOutline } from "@axazara/raiton-icons";

import { useGetOrders } from "../../services";
import { PaginationControls } from "@/components";
import { useOrders } from "./useOrders";
import { useSetPageMeta } from "@/hooks";

export function Orders() {
  useSetPageMeta({
    title: "Orders",
    icon: ShoppingCartOutline,
  });

  const { data: ordersData, isLoading } = useGetOrders({ page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (ordersData?.data && ordersData.data.length > 0)
    return <OrderPageRender />;

  return <EmptyState title="Orders" description="No orders found" />;
}

function OrderPageRender() {
  const {
    // Data and loading states
    orders,
    isLoading,
    countries,
    services,
    providers,

    // Search states and setters
    setSearchQuery,
    setSearchCountryQuery,
    setSearchServiceQuery,
    setSearchProviderQuery,
    searchQuery,
    debouncedSearch,
    searchCountryQuery,
    searchServiceQuery,
    searchProviderQuery,

    // Filter states and setters
    setSelectedStatus,
    setSelectedCountry,
    setSelectedService,
    setSelectedDateRange,
    setSelectedProvider,

    // Dropdown open states and setters
    setOpenedStatusFilter,
    setOpenedCountryFilter,
    setOpenedServiceFilter,
    setOpenedProviderFilter,

    // Pagination
    page,
    setPage,

    // URL Params
    // urlParams,
    setUrlParams,

    // Static and selected filter states
    statusData,
    selectedStatus,
    selectedCountry,
    selectedProvider,
    selectedService,
    openedStatusFilter,
    openedCountryFilter,
    openedProviderFilter,
    openedServiceFilter,
    selectedDateRange,
  } = useOrders();

  return (
    <Box>
      <Box className="pt-4">
        <Box className="relative">
          <LoadingOverlay visible={isLoading} />
          <Box className="py-5 flex flex-row w-full justify-between sticky top-0 bg-white z-10">
            <OrdersFilter
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              debouncedSearch={debouncedSearch}
              searchCountryQuery={searchCountryQuery}
              setSearchCountryQuery={setSearchCountryQuery}
              searchServiceQuery={searchServiceQuery}
              setSearchServiceQuery={setSearchServiceQuery}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              statusData={statusData}
              openedStatusFilter={openedStatusFilter}
              setOpenedStatusFilter={setOpenedStatusFilter}
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              countries={countries}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              openedCountryFilter={openedCountryFilter}
              setOpenedCountryFilter={setOpenedCountryFilter}
              services={services}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              openedServiceFilter={openedServiceFilter}
              setOpenedServiceFilter={setOpenedServiceFilter}
              setUrlParams={setUrlParams}
              setSearchProviderQuery={setSearchProviderQuery}
              searchProviderQuery={searchProviderQuery}
              setSelectedProvider={setSelectedProvider}
              setOpenedProviderFilter={setOpenedProviderFilter}
              openedProviderFilter={openedProviderFilter}
              providers={providers}
              selectedProvider={selectedProvider}
            />
          </Box>
          <Box className="relative">
            {orders?.data && (
              <>
                <OrdersTable orders={orders.data} />
                <PaginationControls
                  page={page}
                  pagination={orders.pagination}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
