import { appPaths } from "@/configs";
import { Box } from "@axazara/raiton-atoms";
import { Link } from "react-router-dom";

export default function AppLogo({ opened }: { opened: boolean }) {
  return (
    <Box className="px-3 flex flex-col items-center justify-center">
      <Box
        component={Link}
        to={appPaths.users.path}
        className={`w-full flex flex-col border-b border-neutral-80 pb-6 mb-6 ${
          opened ? "justify-start items-start" : "justify-center items-center"
        }`}
      >
        {opened ? (
          <Box className="flex flex-row space-x-2">
            <img
              src="https://cdn.axazara.com/brand/crilix/logo.svg"
              alt="crilix"
              width={136}
              height={32}
            />
          </Box>
        ) : (
          <img
            src="https://cdn.axazara.com/brand/crilix/icon.svg"
            alt="crilix"
            width={40}
            height={40}
          />
        )}
      </Box>
    </Box>
  );
}
