import { BaseEntity } from "./base";
import { Bundle } from "./bundle";
import { PaginatedData, PaginatedType } from "./pagination";
import { SimplifiedUser } from "./user";

export interface Refill extends BaseEntity {
  user: SimplifiedUser;
  bundle: Bundle;
  discount_code: DiscountCode;
  amount: string;
  currency: string;
  amount_formatted: string;
  payment: Payment;
  metadata: Metadata;
  status: RefillStatus;
  failure_reason: string;
  failed_at: string;
  completed_at: string;
}

interface Metadata {
  payment_gateway: string;
  payment_transaction_id: string;
  payment_transaction_status: string;
}

export interface DiscountCode {
  id: string;
  code: string;
}

interface Payment {
  transaction_id: string;
  gateway: string;
  status: string;
  amount_formatted: string;
  amount: string;
  currency: string;
  exchange_rate: string;
  correction_rate: string;
}

export enum RefillStatus {
  FAILED = "failed",
  INITIATED = "initiated",
  PENDING_PAYMENT = "pending_payment",
  PAYMENT_FAILED = "payment_failed",
  SUCCESS = "success",
}

export type Refills = Refill[];
export type PaginatedRefills = PaginatedType<PaginatedData, "data", Refills>;
