import { Box, Button, OTPInput, Text } from "@axazara/raiton-atoms";
import { closeModal, useForm } from "@axazara/raiton-molecules";
import { Bundle } from "utilities";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useDeleteBundle } from "../services";

export function DeleteBundleModal({ bundle }: { bundle: Bundle }) {
  const deleteBundleDataForm = useForm<{ otp_code: string }>({
    initialValues: {
      otp_code: "",
    },
  });

  const useDeleteBundleMutation = useDeleteBundle(bundle.id);

  async function handleDelete(values: { otp_code: string }) {
    try {
      await useDeleteBundleMutation.mutateAsync(values);
      showSuccessNotification("Bundle deleted successfully");
      closeModal("");
    } catch (error) {
      showErrorNotification((error as any)?.message);
      closeModal("");
    }
  }

  return (
    <Box>
      <Box
        component="form"
        onSubmit={deleteBundleDataForm.onSubmit(handleDelete)}
        className="flex flex-col"
      >
        <Text className="text-center text-neutral-30 text-p-01 mb-4">
          Bundle deleted successfully
        </Text>
        <Box className="flex justify-center items-center mb-6">
          <OTPInput
            radius={12}
            size="md"
            required
            onChange={(res) => {
              deleteBundleDataForm.setFieldValue("otp_code", res);
            }}
            allowedCharacters="numeric"
          />
        </Box>
        <Box className="flex space-x-2">
          <Button
            fullWidth
            className="text-p-01-semibold"
            type="submit"
            disabled={!deleteBundleDataForm.isDirty()}
            loading={useDeleteBundleMutation.isPending}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
