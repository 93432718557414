import {
  Box,
  Button,
  Divider,
  InputField,
  OTPInput,
  Text,
} from "@axazara/raiton-atoms";
import { Bundle } from "utilities";
import { closeAllModals, closeModal, useForm } from "@axazara/raiton-molecules";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useAddBundle } from "../services";
import { useQueryClient } from "@tanstack/react-query";
import { ApiEndpoints } from "@/configs";

export function AddBundle() {
  const queryClient = useQueryClient();

  const addBundleFormData = useForm<Partial<Bundle> & { otp_code: string }>({
    initialValues: {
      coins: undefined,
      price: undefined,
      sale_price: undefined,
      otp_code: "",
    },
  });

  const useAddBundleMutation = useAddBundle();

  async function handleAddBundle(values: Partial<Bundle>) {
    try {
      const response = await useAddBundleMutation.mutateAsync(values);
      queryClient.invalidateQueries({ queryKey: [ApiEndpoints.bundles] });

      showSuccessNotification((response as any)?.message);

      closeModal("");
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }
  return (
    <Box className="mt-12">
      <Box
        component="form"
        onSubmit={addBundleFormData.onSubmit(handleAddBundle)}
      >
        <InputField
          {...addBundleFormData.getInputProps("coins")}
          label="Coin value"
          classNames={{
            input: "rounded-xl",
            label: "mb-3 text-p-01-semibold text-dark",
          }}
          radius={12}
          placeholder="20 coins"
          size="xs"
          className="w-full mb-4"
        />

        <InputField
          {...addBundleFormData.getInputProps("price")}
          label="Normal price"
          classNames={{
            input: "rounded-xl",
            label: "mb-3 text-p-01-semibold text-dark",
          }}
          radius={12}
          size="xs"
          className="w-full mb-4"
          rightSection="$"
        />

        <InputField
          {...addBundleFormData.getInputProps("sale_price")}
          label="Sale price"
          classNames={{
            input: "rounded-xl",
            label: "mb-3 text-p-01-semibold text-dark",
          }}
          radius={12}
          rightSection="$"
          size="xs"
          className="w-full mb-6"
        />

        <Divider color="neutral.8" className="w-full mb-6" />

        <Box className="flex flex-col mb-8">
          <Text className="text-center text-neutral-30 text-p-01 mb-4">
            Veuillez entrer le code de confirmation que vous voyez sur
            l’application d’authentification
          </Text>
          <Box className="flex justify-center items-center">
            <OTPInput
              radius={12}
              size="md"
              required
              inputClassName=""
              onChange={(res) => {
                addBundleFormData.setFieldValue("otp_code", res);
              }}
              allowedCharacters="numeric"
            />
          </Box>
        </Box>

        <Box className="flex space-x-2">
          <Button
            variant="outline"
            onClick={() => closeAllModals()}
            color="error.4"
            className="text-p-01-semibold"
          >
            Cancel
          </Button>
          <Button
            fullWidth
            className="text-p-01-semibold"
            type="submit"
            disabled={!addBundleFormData.isDirty()}
            loading={useAddBundleMutation.isPending}
          >
            Add bundle
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
