import { InputField } from "@axazara/raiton-atoms";
import { ProviderField } from "utilities";

interface FormBuilderProps {
  fields: ProviderField[];
  form: any;
}
export function ProviderFormBuilder({ fields, form }: FormBuilderProps) {
  return (
    <>
      {fields?.map((field) => (
        <InputField
          required
          key={field.name}
          size="xs"
          type={field.type}
          name={field.name}
          label={field.label}
          {...form.getInputProps(field.name)}
        />
      ))}
    </>
  );
}
