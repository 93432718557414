import { Box, Table } from "@axazara/raiton-atoms";

import { Users } from "utilities";
import { UserTableRow } from "./UserTableRow";

export function UserTable({ users }: { users: Users }) {
  return (
    <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            ID
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Customer
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Create at
          </Table.HeadCell>
          <Table.HeadCell className="flex justify-end text-neutral-20 text-p-01-semibold">
            View
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-neutral-80">
          {users.map((user) => (
            <UserTableRow user={user} key={user.id} />
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
}
