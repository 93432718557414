import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Divider,
  InputField,
  OTPInput,
  Text,
} from "@axazara/raiton-atoms";
import {
  ArrowCircleLeftOutline,
  ProfileCircleOutline,
} from "@axazara/raiton-icons";
import { Provider } from "utilities";
import { useForm } from "@axazara/raiton-molecules";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { appPaths } from "@/configs";
import { useSetPageMeta } from "@/hooks";
import {
  useAddProviderAccount,
  useGetProvider,
} from "@/features/ProviderAccounts/services";
import { ProviderFormBuilder } from "@/features/ProviderAccounts/components";

export function AddProviderFormRender({ code }: { code: string }) {
  const { data: provider } = useGetProvider(code);
  return (
    <Box>{provider && <AddProviderAccountForm provider={provider} />}</Box>
  );
}

function AddProviderAccountForm({ provider }: { provider: Provider }) {
  const navigate = useNavigate();
  const addProviderAccountForm = useForm({
    initialValues: provider?.fields?.reduce(
      (obj: any, item) => {
        obj[item.name] = (provider as any)![item.name];
        return obj;
      },
      { account_name: "", otp_code: "", provider_code: provider?.short_code },
    ),
  });

  const useAddProviderMutation = useAddProviderAccount();

  async function handleAddProviderAccount(payload: any) {
    try {
      const response = await useAddProviderMutation.mutateAsync(payload);
      showSuccessNotification((response as any)?.message);
      navigate(`${appPaths.providerAccount.path}`);
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  return (
    <Box className="pt-4">
      <Box className="flex gap-5 mb-5">
        <ActionIcon
          radius={100}
          onClick={() => navigate("/provider-account/add-provider-account")}
        >
          <ArrowCircleLeftOutline className="w-6 h-6" />
        </ActionIcon>
        <Text className="text-heading-06-semibold">Provider informations</Text>
      </Box>
      <Box
        className="max-w-xl mx-auto w-full pt-8"
        onSubmit={addProviderAccountForm.onSubmit(handleAddProviderAccount)}
        component="form"
      >
        <Box className="flex items-center gap-4 mb-6">
          <Avatar src={provider?.icon_url} size={64} />
          <Text>{provider?.name}</Text>
        </Box>
        <Box className="space-y-6">
          <InputField
            {...addProviderAccountForm.getInputProps("account_name")}
            label="Account name"
            placeholder="Provider Account name"
            size="xs"
          />

          {provider && (
            <ProviderFormBuilder
              form={addProviderAccountForm}
              fields={provider?.fields}
            />
          )}

          <Divider color="neutral.9" className="border-neutral-90" />
          <Box className="flex flex-col items-center justify-center">
            <Text className="text-p-01 text-neutral-30 text-center mb-4">
              Enter the OTP code sent to your phone number
            </Text>
            <OTPInput
              radius={12}
              {...addProviderAccountForm.getInputProps("otp_code")}
              size="lg"
              allowedCharacters="numeric"
              className="flex justify-center"
              onChange={(res) => {
                addProviderAccountForm.setFieldValue("otp_code", res);
              }}
            />
          </Box>
          <Box className="flex justify-center">
            <Button
              disabled={!addProviderAccountForm.isDirty()}
              loading={useAddProviderMutation.isPending}
              type="submit"
            >
              Add provider Account
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function AddProviderForm() {
  useSetPageMeta({
    title: "Provider Account",
    icon: ProfileCircleOutline,
  });

  const { code } = useParams();

  if (!code) {
    return <Navigate to={appPaths.addProvider.path} />;
  }

  return <AddProviderFormRender code={code} />;
}
