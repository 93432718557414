import {
  Box,
  Text,
  Tag,
  Button,
  Avatar,
  Tooltip,
  Divider,
} from "@axazara/raiton-atoms";
import { UserSearchOutline } from "@axazara/raiton-icons";
import { Transaction, formatDate } from "utilities";
import { Link } from "react-router-dom";
import { TransactionsMetadata } from "./TransactionsMetadata";

export function TransactionsDetailsModal({
  transaction,
}: {
  transaction: Transaction;
}) {
  return (
    <Box className="space-y-4 mt-10">
      <Box className="border border-neutral-80 rounded-xl p-4">
        <Box className="flex flex-row justify-between mb-4">
          <Text className="text-heading-06-sm-semibold text-neutral-40">
            Type
          </Text>
          {transaction.type == "debit" && (
            <Tag
              className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-critical-40 text-p-01-semibold"
              >
                Débit
              </Text>
            </Tag>
          )}
          {transaction.type == "credit" && (
            <Tag
              className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-success-40 text-p-01-semibold"
              >
                Crédit
              </Text>
            </Tag>
          )}
        </Box>
        <Box className="flex flex-row justify-between mb-4">
          <Text className="text-heading-06-sm-semibold text-neutral-40">
            Date et heure
          </Text>
          <Text className="text-p-01-medium text-neutral-40">
            {formatDate(transaction?.created_at, "en", "D MMM YYYY, HH:mm")}
          </Text>
        </Box>
        <Box className="flex flex-row justify-between mb-4">
          <Text className="text-heading-06-sm-semibold text-neutral-40">
            Montant
          </Text>
          <Text className="text-p-01-medium text-neutral-40">
            {" "}
            {transaction.amount}
          </Text>
        </Box>
        <Box className="flex flex-row justify-between mb-4">
          <Text className="text-heading-06-sm-semibold text-neutral-40">
            Before balance
          </Text>
          <Text className="text-p-01-medium text-neutral-40">
            {transaction.before_balance} coins
          </Text>
        </Box>
        <Box className="flex flex-row justify-between mb-4">
          <Text className="text-heading-06-sm-semibold text-neutral-40">
            After balance
          </Text>
          <Text className="text-p-01-medium text-neutral-40">
            {transaction.after_balance} coins
          </Text>
        </Box>
      </Box>
      <Box className="border border-neutral-80 rounded-xl p-4 space-y-4">
        <Text className="text-heading-06-sm-semibold text-neutral-40">
          Object
        </Text>
        <Text className="text-p-01 text-neutral-40">{transaction.object}</Text>
      </Box>
      <Box className="flex items-center justify-between mt-6 mb-12 border border-neutral-80 rounded-xl p-4">
        <Box className="flex space-x-4 items-center">
          <Avatar src={transaction?.user.profile_photo_url} size={48} />
          <Box>
            <Text className="text-p-01-semibold mb-1">
              {transaction?.user?.first_name} {transaction?.user?.last_name}
            </Text>
            <Text className="text-p-01-medium text-neutral-40 normal-case">
              {transaction?.user?.email}
            </Text>
          </Box>
        </Box>
        <Box component={Link} to={`/users/${transaction?.user?.id}`}>
          <Button
            variant="outline"
            color="primary.4"
            size="sm"
            rightIcon={<UserSearchOutline className="w-6" />}
            className="text-p-01-semibold w-full md:w-auto"
          >
            See profile
          </Button>
        </Box>
      </Box>

      <Box className="border border-neutral-80 rounded-xl p-4 space-y-4">
        <Text className="text-heading-06-sm-semibold text-neutral-40">
          Object
        </Text>
        <Text className="text-p-01 text-neutral-40">{transaction.object}</Text>
      </Box>

      {transaction?.metadata && (
        <TransactionsMetadata metadata={transaction.metadata} />
      )}

      <Box className="mb-6 border border-neutral-80 rounded-xl p-4">
        <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
          Metadata
        </Text>
        <Divider className="border-neutral-80 mb-6" />

        <Box className="space-y-4">
          <Box className="flex flex-row items-center gap-4 justify-between">
            <Text className="text-neutral-50 text-p-01-medium">key</Text>
            <Tooltip label={""}>
              <Text className="text-neutral-30 text-p-01 whitespace-nowrap text-ellipsis overflow-hidden w-60">
                meta key
              </Text>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
