import React from "react";
import {
  Avatar,
  Box,
  Button,
  Group,
  InputField,
  LoadingOverlay,
  Menu,
  Text,
  UnstyledButton,
} from "@axazara/raiton-atoms";
import {
  AddCircleFill,
  SearchNormal1Outline,
  CloseCircleFill,
  ArrowDownOutline,
  Box1Outline,
} from "@axazara/raiton-icons";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";
import { openModal } from "@axazara/raiton-molecules";
import { Currency, PaginatedBundles, useFilterDropdownStyles } from "utilities";
import { EmptyState } from "@/components";
import { useGetBundles, useGetCurrencies } from "../../services";
import { PaginationControls } from "@/components/PaginationControls";
import { AddBundle, BundleTable } from "../../components";
import { useSetPageMeta } from "@/hooks";

export function Bundle() {
  const { data: bundlesData, isLoading } = useGetBundles({ page: 1 });

  useSetPageMeta({
    title: "Bundles",
    icon: Box1Outline,
  });

  if (isLoading)
    return (
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ color: "secondary" }}
      />
    );

  if (bundlesData && bundlesData?.data?.length > 0)
    return <BundlePageRender bundlesData={bundlesData} />;
  return (
    <Box className="h-full w-full grid place-items-center">
      <EmptyState
        title="No bundle yet"
        primaryAction={
          <Button
            onClick={() =>
              openModal({
                centered: true,
                children: <AddBundle />,
                size: "md",
                title: (
                  <Text className="text-heading-05-bold text-neutral-30">
                    Add bundle
                  </Text>
                ),
              })
            }
            size="xs"
            rightIcon={<AddCircleFill className="w-5 h-5" />}
            className="text-p-01-semibold"
          >
            Add bundle
          </Button>
        }
      />
    </Box>
  );
}

function BundlePageRender({ bundlesData }: { bundlesData: PaginatedBundles }) {
  const statusData = [
    { label: `${"All"}`, value: "all", color: "bg-neutral-50" },
    { label: `${"Active"}`, value: "active", color: "bg-success-50" },
    { label: `${"Inactive"}`, value: "inactive", color: "bg-critical-50" },
  ];

  const [status, setStatus] = React.useState<any>(statusData[0]);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const [page, setPage] = React.useState(1);

  const [currencyOpened, setCurrencyOpened] = React.useState(false);
  const [openedStatusFilter, setOpenedStatusFilter] = React.useState(false);

  const [searchCurrencyQuery, setSearchCurrencyQuery] = React.useState("");
  const [debouncedCurrencySearch] = useDebouncedValue(searchCurrencyQuery, 500);

  const { classes: currencyClasses } = useFilterDropdownStyles({
    opened: currencyOpened,
    width: "35%",
  });
  const { classes: StatusFilterClasses } = useFilterDropdownStyles({
    opened: openedStatusFilter,
    width: "20%",
  });

  const [currencyFilter, setCurrencyFilter] = React.useState<Currency | null>();

  const { data: currencies } = useGetCurrencies({
    page: 1,
    search: debouncedCurrencySearch,
  });

  const { data: bundles, refetch } = useGetBundles({
    page,
    search: debouncedSearch,
    initialData: bundlesData,
    currency: currencyFilter?.code,
    status: status.value,
  });
  const currencyItems = currencies?.map((item) => (
    <Menu.Item
      className={currencyFilter?.name === item.name ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={item?.icon_url} />}
      onClick={() => setCurrencyFilter(item)}
      key={item?.name}
    >
      {item?.name}
    </Menu.Item>
  ));

  const statusItems = statusData.map((item) => (
    <Menu.Item
      icon={<Box className={`w-3 h-3 rounded-full ${item.color}`} />}
      onClick={() => setStatus(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Box>
      <Box className="pt-4">
        <Box className="flex flex-row w-full sticky top-0 z-10 bg-white py-5">
          <Box className="flex gap-2 w-full justify-between ">
            <InputField
              classNames={{ input: "rounded-xl" }}
              radius={12}
              placeholder="Search bundle"
              size="xs"
              icon={<SearchNormal1Outline className="w-5 h-5" />}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full"
              value={searchQuery}
              rightSection={
                <Box>
                  {debouncedSearch ? (
                    <CloseCircleFill
                      className="w-4 h-4 text-neutral-40"
                      onClick={() => {
                        setSearchQuery("");
                      }}
                    />
                  ) : null}
                </Box>
              }
            />
            <Menu
              onOpen={() => setCurrencyOpened(true)}
              onClose={() => setCurrencyOpened(false)}
              radius="md"
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton
                  className={`${currencyClasses.control} space-x-4`}
                >
                  {currencyOpened ? (
                    <InputField
                      onClick={(e) => e.stopPropagation()}
                      styles={() => ({})}
                      classNames={{
                        input:
                          "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                      }}
                      value={searchCurrencyQuery}
                      className="w-full"
                      iconWidth={12}
                      size="xs"
                      onChange={(e) => setSearchCurrencyQuery(e.target.value)}
                      icon={<SearchNormal1Outline className="w-3 h-3" />}
                    />
                  ) : currencyFilter ? (
                    <>
                      <Group spacing="xs" className="w-full">
                        {currencyFilter?.icon_url && (
                          <Avatar size={24} src={currencyFilter?.icon_url} />
                        )}
                        <span
                          className={`${currencyClasses.label} text-ellipsis overflow-hidden whitespace-nowrap w-16`}
                        >
                          {currencyFilter?.name}
                        </span>
                      </Group>
                      {currencyFilter && (
                        <CloseCircleFill
                          className="w-4 h-4 text-neutral-50 mr-2"
                          onClick={() => {
                            setCurrencyFilter(null);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text
                      className="text-footer"
                      styles={() => ({ root: { fontSize: 12 } })}
                    >
                      Filter by currency
                    </Text>
                  )}
                  <ArrowDownOutline
                    className={classNames(
                      currencyClasses.icon,
                      "w-4 h-4 text-neutral-40",
                    )}
                  />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown style={{ maxHeight: "300px", overflow: "auto" }}>
                {currencyItems}
              </Menu.Dropdown>
            </Menu>
            <Menu
              onOpen={() => setOpenedStatusFilter(true)}
              onClose={() => setOpenedStatusFilter(false)}
              radius="md"
              width="max-content"
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton className={StatusFilterClasses.control}>
                  <Group spacing="xs">
                    <Box className={`w-3 h-3 rounded-full ${status?.color}`} />
                    <span className={StatusFilterClasses.label}>
                      {status?.label}
                    </span>
                  </Group>
                  <ArrowDownOutline
                    className={classNames(
                      StatusFilterClasses.icon,
                      "w-4 h-4 text-neutral-40",
                    )}
                  />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>{statusItems}</Menu.Dropdown>
            </Menu>

            <Box>
              <Button
                onClick={() =>
                  openModal({
                    centered: true,
                    children: <AddBundle />,
                    size: "md",
                    title: (
                      <Text className="text-heading-05-bold text-neutral-30">
                        Add bundle
                      </Text>
                    ),
                  })
                }
                size="xs"
                rightIcon={<AddCircleFill className="w-5 h-5" />}
                className="text-p-01-semibold"
              >
                Add bundle
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className="relative">
            <LoadingOverlay
              visible={true}
              loaderProps={{ color: "secondary" }}
            />
          </Box>
          {bundles?.data && (
            <BundleTable bundles={bundles.data} refetch={refetch} />
          )}
          <Box>
            {bundles?.data && (
              <PaginationControls
                page={page}
                pagination={bundles?.pagination}
                onPageChange={setPage}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
