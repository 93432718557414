import { Tag, Text, Box } from "@axazara/raiton-atoms";
import { OrderStatus } from "utilities";

export function OrderStatusBadge({ status }: { status?: OrderStatus }) {
  if (status === OrderStatus.INITIATED) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-primary border border-primary-60 py-3"
        size="lg"
      >
        <Text component="span" className="text-primary-30 text-p-01-semibold">
          Initiated
        </Text>
      </Tag>
    );
  }

  if (status === OrderStatus.PENDING) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-secondary border border-secondary-60 py-3"
        size="lg"
      >
        <Text component="span" className="text-secondary-30 text-p-01-semibold">
          Pending
        </Text>
      </Tag>
    );
  }

  if (status === OrderStatus.COMPLETED) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
        size="lg"
      >
        <Text component="span" className="text-success-30 text-p-01-semibold">
          Completed
        </Text>
      </Tag>
    );
  }

  if (status === OrderStatus.FAILED) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3"
        size="lg"
      >
        <Text component="span" className="text-critical-30 text-p-01-semibold">
          Failed
        </Text>
      </Tag>
    );
  }

  if (status === OrderStatus.EXPIRED) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3"
        size="lg"
      >
        <Text component="span" className="text-critical-30 text-p-01-semibold">
          Expired
        </Text>
      </Tag>
    );
  }

  if (status === OrderStatus.REFUNDED) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3"
        size="lg"
      >
        <Text component="span" className="text-critical-30 text-p-01-semibold">
          Refunded
        </Text>
      </Tag>
    );
  }

  return <Box />;
}
