import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Divider,
  LoadingOverlay,
  Surface,
  Text,
} from "@axazara/raiton-atoms";
import {
  ShoppingCartOutline,
  ArrowCircleLeftOutline,
  EyeOutline,
} from "@axazara/raiton-icons";
import { openModal } from "@axazara/raiton-molecules";
import { formatDate } from "utilities";
import { OrderRefundModal, OrderSms } from "../../components";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useSetPageMeta } from "@/hooks";
import { appPaths } from "@/configs";
import { useGetOrderMessages, useGetOrdersDetails } from "../../services";
import {
  CopyableInformationItem,
  InformationRow,
  OrderStatusBadge,
} from "@/components";

function OrderDetailsPageRender({ id }: { id: string }) {
  const navigate = useNavigate();

  const { data: order, isError, isLoading, refetch } = useGetOrdersDetails(id);
  const { data: orderMessages } = useGetOrderMessages(id);

  if (isLoading) return <LoadingOverlay visible />;

  if (isError) return <Navigate to={appPaths.orders.path} />;

  return (
    <Box>
      <Box className="pt-4">
        <Box className="flex justify-between items-center">
          <Box className="flex space-x-4 mb-4">
            <ActionIcon radius={100} onClick={() => navigate(-1)}>
              <ArrowCircleLeftOutline className="w-6 h-6" />
            </ActionIcon>
            <Text className="text-heading-05-bold">{order?.id}</Text>
            <OrderStatusBadge status={order?.status} />
          </Box>
          <Box className="flex gap-3 mb-6">
            {order?.status === "completed" && (
              <Button
                color="error.3"
                size="xs"
                onClick={() =>
                  openModal({
                    padding: 32,
                    size: "lg",
                    centered: true,
                    title: (
                      <Text className=" text-neutral-30 text-heading-05-bold">
                        Refund Order
                      </Text>
                    ),
                    children: (
                      <OrderRefundModal order={order} refetch={refetch} />
                    ),
                  })
                }
              >
                Refund
              </Button>
            )}
          </Box>
        </Box>
        <Divider color="neutral.6" className="mb-8" />
        <Box className="flex gap-4">
          <Box className="w-full">
            <Surface className="p-4 border border-neutral-80 space-y-4 w-full mb-4">
              <CopyableInformationItem label="Id" value={order?.id} />
              <Box>
                <Text className="text-p-01 text-neutral-40 mb-4">
                  Customers
                </Text>
                <Box className="flex justify-between">
                  <Box className="flex items-center gap-4">
                    <Avatar src={order?.user.profile_photo_url} size={48} />
                    <Box>
                      <Text className="text-p-01 text-neutral-40">
                        {order?.user.first_name} {order?.user.last_name}
                      </Text>
                      <Text className="text-p-01 text-neutral-40">
                        {order?.user.email}
                      </Text>
                    </Box>
                  </Box>
                  <Button
                    rightIcon={<EyeOutline className="w-6 h-6" />}
                    variant="outline"
                    size="xs"
                    component={Link}
                    to={`/users/${order?.user?.id}`}
                  >
                    View
                  </Button>
                </Box>
              </Box>
              <InformationRow
                label="Phone number"
                value={order?.phone_number?.international}
              />
              <InformationRow
                label="Service"
                value={
                  <Box className="flex gap-4 items-center">
                    <Avatar src={order?.service?.icon_url} size={48} />
                    <Text>{order?.service.name}</Text>
                  </Box>
                }
              />
              <InformationRow
                label="Country"
                value={
                  <Box className="flex gap-4 items-center">
                    <Avatar src={order?.country.flag} size={32} />
                    <Text>{order?.country.name}</Text>
                  </Box>
                }
              />
              <InformationRow
                label="Status"
                value={<OrderStatusBadge status={order?.status} />}
              />
              <CopyableInformationItem
                label="Provider Id"
                value={order?.provider_order_id}
              />
              <InformationRow
                label="Amount"
                value={<Box>{order?.price} coins</Box>}
              />
              <InformationRow
                label="Created at"
                value={
                  order?.created_at &&
                  formatDate(order?.created_at, "en", "D MMM YYYY, HH:mm:ss")
                }
              />

              {order?.pending_at && (
                <InformationRow
                  label="Pending at"
                  value={
                    order?.pending_at &&
                    formatDate(order?.pending_at, "en", "D MMM YYYY, HH:mm:ss")
                  }
                />
              )}

              {order?.completed_at && (
                <InformationRow
                  label="Completed at"
                  value={
                    order?.completed_at &&
                    formatDate(
                      order?.completed_at,
                      "en",
                      "D MMM YYYY, HH:mm:ss",
                    )
                  }
                />
              )}

              {order?.expire_at && (
                <InformationRow
                  label="Expire at"
                  value={
                    order?.expire_at &&
                    formatDate(order?.expire_at, "en", "D MMM YYYY, HH:mm:ss")
                  }
                />
              )}

              {order?.expired_at && (
                <InformationRow
                  label="Expired at"
                  value={
                    order?.expired_at &&
                    formatDate(order?.expired_at, "en", "D MMM YYYY, HH:mm:ss")
                  }
                />
              )}

              {order?.refunded_at && (
                <InformationRow
                  label="Refunded at"
                  value={
                    order?.refunded_at &&
                    formatDate(order?.refunded_at, "en", "D MMM YYYY, HH:mm:ss")
                  }
                />
              )}
            </Surface>
            <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
              <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
                Provider
              </Text>

              <Box className="flex items-center gap-4 mb-4">
                <Avatar src={order?.provider?.icon_url} size={48} />
                <Box>
                  <Text className="text-p-01 text-neutral-40">
                    {order?.provider?.account_name}
                  </Text>
                  <Text className="text-p-01 text-neutral-40">
                    {order?.provider?.name}
                  </Text>
                </Box>
              </Box>

              {order?.failure_message && (
                <Box className="mb-4">
                  <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
                    Failed message
                  </Text>

                  <Text className="text-p-01-medium text-neutral-40">
                    {order?.failure_message}
                  </Text>
                </Box>
              )}
            </Surface>
          </Box>
          <Box className="w-full mb-4">
            {orderMessages && orderMessages.messages && (
              <OrderSms sms_received={orderMessages.messages} />
            )}

            {order?.status === "refunded" && order?.refund_reason && (
              <Surface
                radius={18}
                className="p-4 border border-neutral-80 mb-4"
              >
                <Box className="mb-4">
                  <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
                    Refund reason
                  </Text>

                  <Text className="text-p-01 text-neutral-40">
                    {order?.refund_reason}
                  </Text>
                </Box>

                {order?.refund_note && (
                  <Box className="mb-4">
                    <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
                      Refund note
                    </Text>

                    <Text className="text-p-01 text-neutral-40">
                      {order?.refund_note}
                    </Text>
                  </Box>
                )}
                <Box className="mt-8">
                  <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
                    Refunded by
                  </Text>

                  <Box className="flex justify-between">
                    <Box className="flex items-center gap-4">
                      <Avatar
                        src={order?.refunded_by?.profile_photo_url}
                        size={48}
                      />
                      <Box>
                        <Text className="text-p-01 text-neutral-40">
                          {order?.refunded_by?.first_name}{" "}
                          {order?.refunded_by?.last_name}
                        </Text>
                        <Text className="text-p-01 text-neutral-40">
                          {order?.refunded_by?.email}
                        </Text>
                      </Box>
                    </Box>
                    <Button
                      rightIcon={<EyeOutline className="w-6 h-6" />}
                      variant="outline"
                      size="xs"
                      component={Link}
                      to={`/staffs/${order?.refunded_by?.id}`}
                    >
                      View
                    </Button>
                  </Box>
                </Box>
              </Surface>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function OrderDetails() {
  useSetPageMeta({
    title: "Orders",
    icon: ShoppingCartOutline,
  });

  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.orders.path} />;
  }

  return <OrderDetailsPageRender id={id} />;
}
